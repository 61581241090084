import React, {useState,useEffect} from 'react';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chevrolet from '../../../assets_landingpage/img/duedate.jpg'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';


function Dashboard() {
  const [totalData, setTotalData] = useState("");
  const history = useHistory();

  const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'usercode': localStorage.getItem('username'),
          'token': localStorage.getItem('token'),
      }
  };

  useEffect(() => {

    const fetchData = async () => { 
    const res = await fetch(
        'https://revasamudra.ivantie.com/backend/revasamudra/api/invoiceDueDateView',settings
    );
    const json = await res.json();
    if(json.status == true){
        setTotalData(json.totalData);
    }else{
      setTotalData(0);
    }
    
    };
    fetchData(); 
  }, []);

  function lihat(id){
    history.push('/invoice_due_date_report');
  }

  return (
 
        
    <div>
    <Header_admin></Header_admin>
    <main id="main" class="main">

      <div class="pagetitle">
        <h1>Dashboard</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active">Home</li>
          </ol>
        </nav>
      </div>

      <section class="section">
        <div class="row">
          <div class="col-lg-12">

            <div class="card">
              <div class="card-body">
              <div style={{margin: '2%'}}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="auto"
                  image={Chevrolet}
                  alt="Chevrolet"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Invoice Belum Lunas
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  <p style={{fontWeight: 'bold',fontSize: '25px',color: 'red'}}>{totalData} <i class="bi bi-file-earmark-check" style={{color:'black'}}></i></p> 
                  </Typography>
                </CardContent>
                <CardActions>
                  <button type="button" size="small" className="button_edit" onClick={() => lihat()}>Lihat</button>
                </CardActions>
              </Card>
              </div>

              </div>
            </div>

          </div>
        </div>
      </section>

    </main>
    <Footer_admin></Footer_admin>
    </div>
        
  );
}

export default Dashboard;