import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { edit_customer } from '../../../Context/actions';
import LoadingSpinner from "../../loading/LoadingSpinner";
import LoadingBar from 'react-top-loading-bar';

function Edit_customer() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [customerEdit, setCustomerEdit] = useState([]);

    const [customerCode, setCustomerCode] = useState();
    const [customerName, setCustomerName] = useState();
    const [customerAddress, setCustomerAddress] = useState();
    const [customerPhone, setCustomerPhone] = useState();
    const [customerContactPerson, setCustomerContactPerson] = useState();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const history = useHistory();

    useEffect(() => {
        setProgress(progress + 40)
        const settings = {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'usercode': localStorage.getItem('username'),
              'token': localStorage.getItem('token'),
          }
        };
        const fetchData = async () => {
            
          const res = await fetch(
            'https://revasamudra.ivantie.com/backend/revasamudra/api/editCustomerPage/'+params.get("id"), settings
          );
          const json = await res.json();
          setProgress(100)
          setCustomerEdit(json.data);
          setCustomerCode(json.data[0].customerCode);
          setCustomerName(json.data[0].customerName);
          setCustomerAddress(json.data[0].customerAddress);
          setCustomerPhone(json.data[0].customerPhone);
          setCustomerContactPerson(json.data[0].customerContactPerson);
        };
        fetchData();
    },[]);

    const handleonSubmit = async e => {
    e.preventDefault();
        setLoading(true)
        setProgress(progress + 40)
        const promise_login = Promise.resolve(edit_customer(params.get("id"),customerCode,customerName,customerAddress,customerPhone,customerContactPerson));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/customer');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                //username password salah
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/customer');
                window.location.reload(true);
            }
        });
        
    }

    

  return (
    <div>
      <div>
          <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          /> 
          <Header_admin></Header_admin>
          <main id="main" class="main">

            <div class="pagetitle">
              <h1>Ubah Pelanggan</h1>
              <nav>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li class="breadcrumb-item"><Link to="/customer">Pelanggan</Link></li>
                  <li class="breadcrumb-item active">Ubah Pelanggan</li>
                </ol>
              </nav>
            </div>

            <section class="section">
              <div class="row">
                <div class="col-lg-12">

                  <div class="card">
                    <div class="card-body">
                          <form onSubmit={handleonSubmit}>
                          {customerEdit.map(item => (
                            <React.Fragment>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Kode Pelanggan</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="customerCode" id="customerCode" defaultValue={item.customerCode} value={customerCode} onChange={(e) => setCustomerCode(e.target.value)} disabled="true" />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Nama Pelanggan</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="customerName" id="customerName" defaultValue={item.customerName} value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Alamat Pelanggan</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="customerAddress" id="customerAddress" defaultValue={item.customerAddress} value={customerAddress} onChange={(e) => setCustomerAddress(e.target.value)} />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Telepon Pelanggan</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="customerPhone" id="customerPhone" defaultValue={item.customerPhone} value={customerPhone} onChange={(e) => setCustomerPhone(e.target.value)} />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">CP Pelanggan</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="customerContactPerson" id="customerContactPerson" defaultValue={item.customerContactPerson} value={customerContactPerson} onChange={(e) => setCustomerContactPerson(e.target.value)} />
                                  </div>
                              </div>
                            </React.Fragment>
                          ))}
                          

                          <div class="row mb-3">
                              <label for="inputText" class="col-sm-2 col-form-label"></label>
                              <div class="col-sm-12">
                                  <button type="submit" className="button_simpan" disabled={loading}>
                                    {loading ? (
                                      <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                      />
                                    ):
                                    (<i
                                      className="fa fa-save"
                                      style={{ marginRight: "5px" }}
                                    />)} &nbsp;
                                    {loading && <span>Harap Tunggu ...</span>}
                                    {!loading && <span>Simpan</span>}
                                  </button>
                                  
                              </div>
                          </div>

                          </form>

                    </div>
                  </div>

                </div>
              </div>
            </section>

          </main>
          <Footer_admin></Footer_admin>
        </div>
    </div>
  );
}

export default Edit_customer;