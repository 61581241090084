import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import axios from 'axios';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {Modal, Button, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { delete_fleet } from '../../../Context/actions';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';


import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

function Fleet() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [progress, setProgress] = useState(0)

  const history = useHistory();

  const [fleetNumberPlate, setFleetNumberPlate] = useState();
  const [fleetNote, setFleetNote] = useState();
  const [fleetOwnership, setFleetOwnership] = useState();
  

  const [fleet, setFleet] = useState([]);

  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    let url;
      const settings = {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'usercode': localStorage.getItem('username'),
              'token': localStorage.getItem('token'),
          }
      };
      

      const fetchData = async () => {
        
      if(query == ''){
        
          url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showFleetGetDataPagination';
          const res = await fetch(
              url,settings
          );
          const json = await res.json();
          
          
          if(json.status == "true"){
              console.log(json.pages)
              setPage(page);
              setPages(json.pages);
              setRows(json.rows);
          }
          
      };

          
      }

      fetchData();
  }, [page, keyword]);

  useEffect(() => {
      let url;
      setProgress(progress + 40);
      if(query == ''){
          if(awal == undefined){
              url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showFleetPagination/0';
          }else{
              url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showFleetPagination/'+awal;
          }
          const settings = {
              method: 'GET',
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'usercode': localStorage.getItem('username'),
                  'token': localStorage.getItem('token'),
              }
          };
          const fetchData = async () => {
          const res = await fetch(
              url,settings
          );
          const json = await res.json();
          
          setProgress(100);
          setFleet(json.data);
          
          };
          fetchData();
      }
    },[]);

  const changePage = ({ selected }) => {
      setProgress(progress + 40);
      if(query == ''){
          setPage(selected+1);
          
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);
          const settings = {
              method: 'GET',
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'usercode': localStorage.getItem('username'),
                  'token': localStorage.getItem('token'),
              }
          };
          const fetchData = async () => {
          const res = await fetch(
              'https://revasamudra.ivantie.com/backend/revasamudra/api/showFleetPagination/'+awal,settings
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setFleet([]);
              setFleet(json.data);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }else{
          setPage(selected+1);
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);
          const settings = {
              method: 'GET',
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'usercode': localStorage.getItem('username'),
                  'token': localStorage.getItem('token'),
              }
          };
          const fetchData = async () => {
          const res = await fetch(
              'https://revasamudra.ivantie.com/backend/revasamudra/api/showFleetPaginationSearch/'+awal+'/'+query,settings
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setFleet([]);
              setFleet(json.data);
              
              setPages(json.pages);
              setRows(json.rows);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }
      
  };

  function searchData(){
      setProgress(progress + 40);
      setLoadingCari(true)
      const settings = {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'usercode': localStorage.getItem('username'),
              'token': localStorage.getItem('token'),
          }
      };
      const fetchData = async () => { 
      const res = await fetch(
          'https://revasamudra.ivantie.com/backend/revasamudra/api/showFleetPaginationSearch/0/'+query,settings
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setLoadingCari(false)
          setFleet([]);
          setPages(0);
          setRows(0);
          setPage(1);
          setFleet(json.data);
          setPages(json.pages);
          setRows(json.rows);
          
      }else{
          setProgress(100);
          setLoadingCari(false)
          setFleet(json.data);
          setPages(json.pages);
          setRows(json.rows);
          setMsg(
              "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Country!"
          );
      }
      
      };
      fetchData(); 
  }; 

  function resetPencarian(){
      setLoadingCari(true)
      setLoadingResetPencarian(true)
      setQuery("");
      setTimeout(
          function() {
            setLoadingCari(false)
            setLoadingResetPencarian(false)
            window.location.reload(true);
          }
          ,
          2000
      );
  }

  
  function edit_fleet(id){
    history.push('/edit_fleet?id='+id);
  }

  function add_fleet(id){
    history.push('/add_fleet');
  }

  
  function delete_fleet_onclick(id){
    if (window.confirm('Are you sure you want to delete this ?')) {
      setProgress(progress + 40)
      setLoading(true)
      const promise_login = Promise.resolve(delete_fleet(id));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                setProgress(100)
                setLoading(false)
                alert(value.message);
                history.push('/fleet');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setProgress(100)
                setLoading(false)
                alert(value.message);
                history.push('/fleet');
                window.location.reload(true);
            }
        });
    } else {
      setProgress(100)
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(()=>{
      $("#example2").DataTable({
          destroy: true,
          //dom: "rBftlip",
          // buttons: [
          //   {

          //   },
          // ],
          lengthMenu: [
            [10, 20, 50, 100, -1],
            [10, 20, 50, 100, "All"],
          ],
          pageLength: 10,
      });
    },1000)
}, [])

  return (
      <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        /> 
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Data Armada</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Armada</li>
              </ol>
            </nav>
          </div>

          <section class="section">
          <div class="row">
                  <div class="col-lg-4">
                      <button className="button_tambah_item" style={{width: "100%"}} onClick={(e) => {
                          e.preventDefault();
                          window.location.href='/add_fleet';
                          }}>
                        <i className="fa fa-plus"
                          style={{ marginRight: "5px" }}
                        />
                        &nbsp;
                        Tambah Armada
                      </button>
                  </div>
                  <div class="col-lg-4 mt-1">
                  <input
                        type="text"
                        className="form-control"
                        style={{width: "100%"}}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Cari sesuatu disini..."
                      />
                  </div>
                  <div class="col-lg-2">
                  <button type="button" style={{width: "100%"}} onClick={searchData} className="button_cari" disabled={loadingCari}>
                        {loadingCari ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-search"
                          style={{ marginRight: "5px" }}
                        />)}
                        {loadingCari && <span>Cari</span>}
                        {!loadingCari && <span>Cari</span>}
                      </button>
                  </div>
                  
                  <div class="col-lg-2">
                      <button type="button" style={{width: "100%"}} onClick={resetPencarian} className="button_reset_pencarian" disabled={loadingResetPencarian}>
                        {loadingResetPencarian ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-refresh"
                          style={{ marginRight: "5px" }}
                        />)}
                        {loadingResetPencarian && <span>Reset Pencarian</span>}
                        {!loadingResetPencarian && <span>Reset Pencarian</span>}
                      </button>
                  </div>
                  
                </div>
            <br />
            <div class="row">
              <div class="col-lg-12">
              <div class="table-responsive">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Plat Nomor</th>
                    <th>Note</th>
                    <th>Kepemilikan</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {
                  fleet != 'Data Tidak Tersedia' ?
                  fleet.map(fleet => (
                    
                      <tr>
                        <td>{fleet.numberPlate}</td>
                        <td>{fleet.note}</td>
                        <td>({fleet.ownership}) &nbsp;
                        {fleet.ownership == 'MS' ? (
                            'Mobil Sendiri'
                        ):
                        (
                            'Mobil Orang Lain'
                        )}
                        </td>
                        <td>
                        <div class="w3-show-inline-block">
                          <div class="w3-bar">
                            <button type="button" class="button_edit" onClick={() => edit_fleet(fleet.id)} disabled={loading}>
                              {loading ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-edit"
                                style={{ marginRight: "5px" }}
                              />)} &nbsp;
                              {loading && <span>Edit</span>}
                              {!loading && <span>Edit</span>}
                            </button>&nbsp;
                            <button type="button" class="button_hapus" disabled={loading} onClick={() => delete_fleet_onclick(fleet.id)}>
                              {loading ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-times-circle"
                                style={{ marginRight: "5px" }}
                              />)} &nbsp;
                              {loading && <span>Hapus</span>}
                              {!loading && <span>Hapus</span>}
                            </button>
                          </div>
                        </div>
                        </td>
                      </tr>
                    
                    ))
                    :
                    <tr>
                      <td colSpan={5}>Data Tidak Tersedia</td>
                    </tr>
                }
                  
                  
                </tbody>
              </table>
              </div>
              <p>
                  Total Rows: {rows} Page: {page} of {pages}
              </p>
              <p className="has-text-centered has-text-danger">{msg}</p>

              <div id="container">
                  <ReactPaginate
                      previousLabel={"< Prev"}
                      nextLabel={"Next >"}
                      pageCount={pages}
                      onPageChange={changePage}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      containerClassName={'pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}
                  />
              </div>
              </div>
            </div>
          </section>

        </main>

        <Footer_admin></Footer_admin>

      </div>
  );
}

export default Fleet;