import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import Moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Tracking() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(false);
  let [partner, setPartner] = useState("0");
  let [statusData, setStatusData] = useState("0");
  let [oriLoad, setOriLoad] = useState("0");
  const [origin, setOrigin] = useState([]);
  const [destination, setDestination] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get('https://athaexpress.net/backend/ongkir/api/tracking/'+params.get("id"))
    .then((response) => {
        setTracking(response.data.data.process);
        setOrigin(response.data.origin_destination.origin);
        setDestination(response.data.origin_destination.destination);
        if(response.data.origin_destination.origin === "-" || response.data.origin_destination.origin === "Tidak Teridentifikasi"){
          console.log("Kosong")
          setOriLoad("0");
        }else{
          console.log("Ada")
          setOriLoad("1");
        }
        if(response.data.status === "false"){
          console.log(response.data.status)
          setStatusData("2");
        }else{
          console.log(response.data.status)
          setStatusData("1");
        }
        setPartner(response.data.partner);
        setLoading(false);
    });
  },[]);

  return(
    <div>
      <div id="header_tracking">    
        <div class="center text-center">
            <div class="navigation_tracking">
              <div class="nav-container">
                <div class="brand">
                  <img class="left-img" src={require('../../assets_landingpage/img/fasworld.jpg')} width="110px" height="60px" alt="" />
                </div>
                <nav class="nav__atas_tracking">
                  <div class="nav-mobile-tracking">
                    <a id="nav-toggle" href="#!"><span></span></a>
                  </div>
                  <ul class="nav-list">
                    <li><Link to="/halaman_utama">Beranda</Link></li>
                    <li><Link to="/portofolio">Portofolio</Link></li>
                    <li><Link to="/superiority">Keunggulan</Link></li>
                    <li><Link to="/kontak">Kontak</Link></li>
                    <li><Link to="/termsandcondition">Syarat dan Ketentuan</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
        </div>
      </div>
      
      <div class="container_tracking">
        <div class="title_tracking">
          <div class="awb"> Nomer AWB : <i>{params.get("id")}</i></div>
          {oriLoad === "0" ? (null) : (<div class="route">{origin} &nbsp; <img src={require('../../assets_landingpage/img/right.png')} alt="right" width="50" height="25" /> &nbsp; {destination}</div>)}
        </div>
        <div class="title_tracking_2">
          {statusData === "2" ? (<div>Data Tidak Ditemukan</div>): statusData === "1" ? (<div>Semua Update Pengiriman</div>):(<div></div>)}  
        </div>
        {loading ? (
          <div class="loader-container">
           
            <main id="main" class="main">
              <div class="spinner"></div><br />
            </main>
            <div id="proses_ambil_data">Proses Ambil Data</div>
          </div>
        ) : (
          statusData === "2" ? (
            null
          ):(
            <div class="rb-container">
              <ul class="rb">
                {
                    tracking.map(item => (
                      <li class="rb-item" ng-repeat="itembx">
                        <div class="timestamp">
                          {Moment(item.time).format("DD/MM/YYYY")}<br /> {Moment(item.time).format("h:mm:ss a")}
                        </div>
                        <div class="item-title">{item.status}</div>
                        {item.location == "-" ? (null):(<div class="item-location">Location : {item.location}</div>)}
                      </li>
                      ))
                }
                
              </ul>
            </div>
          )
        
        )}
        
      </div>

      <div id="footer_tracking" class="dark-wrapper-tracking">
          <div class="container inner">
              <div class="row logo_footer">
                  <div class="col-sm-12">
                    Fast World Express
                  </div>
              </div>
              <div class="row alamat">
                  <div class="col-sm-12">
                    Wiyung Indah XVIII NO 16 A,<br />
                    Jawa Timur, Kota Surabaya, Kecamatan Wiyung.
                  </div>
              </div>
              <div class="row social">
                  <div class="col-sm-12">
                      <div class="social-bar">
                          <a href="#" class="fa fa-instagram tooltipped" title="instagram"></a>
                          <a href="#" class="fa fa-youtube-square tooltipped" title="youtube"></a>
                          <a href="#" class="fa fa-facebook-square tooltipped" title="facebook"></a>
                          <a href="#" class="fa fa-twitter-square tooltipped" title="twitter"></a>
                          <a href="#" class="fa fa-google-plus-square tooltipped" title="google+"></a>
                      </div>
                  </div>
              </div>
              <div class="row copyright">
                  <div class="col-sm-12">
                    &copy; Copyright Fast World Express 2022
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}