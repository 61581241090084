import React, {useEffect, useState} from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { edit_profile } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';

import Select from "react-select";

function Edit_profile() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [selectRole, setSelectRole] = useState([]);

    const [file, setFile] = useState();

    const [isCheckedDashboard, setIsCheckedDashboard] = useState(false);
    const [isCheckedCountry, setIsCheckedCountry] = useState();
    const [isCheckedTypePrice, setIsCheckedTypePrice] = useState();
    const [isCheckedPrice, setIsCheckedPrice] = useState();
    const [isCheckedTypeItem, setIsCheckedTypeItem] = useState();
    const [isCheckedOrder, setIsCheckedOrder] = useState();
    const [isCheckedPengguna, setIsCheckedPengguna] = useState();
    const [isCheckedAramex, setIsCheckedAramex] = useState();

    const [newhakakses, setNewHakAkses] = useState([]);

    const [userEdit, setUserEdit] = useState([]);
    const [usercode, setUsercode] = useState();
    const [name, setName] = useState();
    const [address, setAddress] = useState();
    const [roleId, setRoleId] = useState();
    const [hakakses, setHakakses] = useState();
    const [logoresi, setLogoResi] = useState(null);

    const [idRole, setIdRole] = useState([]);

    const [parDashboard, setParDashboard] = useState();
    const [parCountry, setParCountry] = useState();
    const [parTypePrice, setParTypePrice] = useState();
    const [parPrice, setParPrice] = useState();
    const [parTypeItem, setParTypeItem] = useState();
    const [parOrder, setParOrder] = useState();
    const [parPengguna, setParPengguna] = useState();

    const [array,setArray] = useState([]);

    const checkList = ["Dashboard", "Country", "Type Price", "Price", "Type Item", "Order", "Pengguna"];
    const [checked, setChecked] = useState([]);
    let updatedList = [...checked];

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const history = useHistory();

    useEffect(() => {
        fetch("https://athaexpress.net/backend/ongkir/api/showRole")
        .then((res) => res.json())
        .then((data) => {
          const userDataRole = data.data.map((item) => ({
            label: item.nama_role, // <-- input values you are matching
            value: item.id
          }));
          setSelectRole(userDataRole);
        });
      },[]);

    useEffect(() => {
        setProgress(progress + 40)
        setLoading(true)
        const fetchData = async () => {
            
          const res = await fetch(
            'https://athaexpress.net/backend/ongkir/api/editUserProfile/'+params.get("username"),
          );
          const json = await res.json();
            setProgress(100);
            setLoading(false);
            setUserEdit(json.data);
            setUsercode(json.data[0].usercode);
            setName(json.data[0].name);
            setAddress(json.data[0].address);
            setRoleId(json.data[0].role);
            setHakakses(json.data[0].hak_akses);
            
            for (var i = 0; i < json.data[0].hak_akses.split('').length; i++) {
                if(json.data[0].hak_akses.split('')[i] === '1'){
                    setIsCheckedDashboard(true);
                }else if(json.data[0].hak_akses.split('')[i] == '2'){
                    setIsCheckedCountry(true);
                }else if(json.data[0].hak_akses.split('')[i] == '3'){
                    setIsCheckedTypePrice(true);
                }else if(json.data[0].hak_akses.split('')[i] == '4'){
                    setIsCheckedPrice(true);
                }else if(json.data[0].hak_akses.split('')[i] == '5'){
                    setIsCheckedTypeItem(true);
                }else if(json.data[0].hak_akses.split('')[i] == '6'){
                    setIsCheckedOrder(true);
                }else if(json.data[0].hak_akses.split('')[i] == '7'){
                    setIsCheckedPengguna(true);
                }else if(json.data[0].hak_akses.split('')[i] == '8'){
                    setIsCheckedAramex(true);
                }
            }

            setLogoResi(json.data[0].logo_resi);
         
          
        };
        
        fetchData();
    },[]);

    
    
    const handleonSubmit = async e => {
    e.preventDefault();


        if(logoresi.size > 1e6){
            window.alert("Please upload a file smaller than 1 MB");
        }else{
            let akses,satu,dua,tiga,empat,lima,enam,tujuh,delapan;
            const akses1 = [];
            
            if(isCheckedDashboard){
                akses1.push('1')
            }else{
                akses1.push('0')
            }
            if(isCheckedCountry){
                akses1.push('2')
            }else{
                akses1.push('0')
            }
            if(isCheckedTypePrice){
                akses1.push('3')
            }else{
                akses1.push('0')
            }

            if(isCheckedPrice){
                akses1.push('4')
            }else{
                akses1.push('0')
            }

            if(isCheckedTypeItem){
                akses1.push('5')
            }else{
                akses1.push('0')
            }

            if(isCheckedPengguna){
                akses1.push('6')
            }else{
                akses1.push('0')
            }

            if(isCheckedOrder){
                akses1.push('7')
            }else{
                akses1.push('0')
            }

            if(isCheckedAramex){
                akses1.push('8')
            }else{
                akses1.push('0')
            }

            if(akses1[0] == '0'){
                satu = '';
            }else{
                satu = '1';
            }
            if(akses1[1] == '0'){
                dua = '';
            }else{
                dua = '2';
            }
            if(akses1[2] == '0'){
                tiga = '';
            }else{
                tiga = '3';
            }
            if(akses1[3] == '0'){
                empat = '';
            }else{
                empat = '4';
            }
            if(akses1[4] == '0'){
                lima = '';
            }else{
                lima = '5';
            }
            if(akses1[5] == '0'){
                enam = '';
            }else{
                enam = '6';
            }
            if(akses1[6] == '0'){
                tujuh = '';
            }else{
                tujuh = '7';
            }
            if(akses1[7] == '0'){
                delapan = '';
            }else{
                delapan = '8';
            }

            akses = satu+dua+tiga+empat+lima+enam+tujuh+delapan;
            
            setProgress(progress + 40)
            setLoading(true)

            const decodePar = base64_decode(params.get("username"));
            console.log(usercode);
            if(decodePar == localStorage.getItem('username')){
                const promise_login = Promise.resolve(edit_profile(decodePar,usercode,name,address,roleId,akses,logoresi));
    
                promise_login.then((value) => {
                    if(value.hasil == '1'){
                        //sukses
                        setProgress(100);
                        setLoading(false);
                        alert(value.message);
                        history.push('/profile');
                        window.location.reload(true);
                    }else if(value.hasil == '2'){
                        //username password salah
                        setProgress(100);
                        setLoading(false);
                        alert(value.message);
                        history.push('/profile');
                        window.location.reload(true);
                    }
                });
            }else{
                alert("Username anda tidak sesuai dengan data yang anda edit.");
            }
        }
    }


    const handleChangeRole = (idRole) => {
        setIdRole({ idRole });
    }


    function handleChangeDashboard(){
        if(isCheckedDashboard){
            setIsCheckedDashboard(false);
        }else{
            setIsCheckedDashboard(true);
        }
    }

    function handleChangeCountry(){
        if(isCheckedCountry){
            setIsCheckedCountry(false);
        }else{
            setIsCheckedCountry(true);
        }
    }

    function handleChangeTypePrice(){
        if(isCheckedTypePrice){
            setIsCheckedTypePrice(false);
        }else{
            setIsCheckedTypePrice(true);
        }
    }

    function handleChangePrice(){
        if(isCheckedPrice){
            setIsCheckedPrice(false);
        }else{
            setIsCheckedPrice(true);
        }
    }

    function handleChangeTypeItem(){
        if(isCheckedTypeItem){
            setIsCheckedTypeItem(false);
        }else{
            setIsCheckedTypeItem(true);
        }
    }

    function handleChangeOrder(){
        if(isCheckedOrder){
            setIsCheckedOrder(false);
        }else{
            setIsCheckedOrder(true);
        }
    }

    function handleChangePengguna(){
        if(isCheckedPengguna){
            setIsCheckedPengguna(false);
        }else{
            setIsCheckedPengguna(true);
        }
    }

    function handleChangeFile(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
        setLogoResi(e.target.files[0]);
    }

    function resetFile() {
        setFile('');
        document.getElementById('logoResi').value = '';
    }

    const myImageStyle = { width: '200px', height: '250px', border:'5px solid #555' };

  return (
        <div>
            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Edit User</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/users">Users</Link></li>
                    <li class="breadcrumb-item active">Edit Users</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Edit Users</h5>
                            <form onSubmit={handleonSubmit}>
                            {userEdit.map(item => (
                                <React.Fragment>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Username</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="username" id="username" defaultValue={item.usercode} value={usercode} onChange={(e) => setUsercode(e.target.value)} />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Name</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="nama" id="nama" defaultValue={item.name} value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Address</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="address" id="address" defaultValue={item.address} value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Role</label>
                                <div class="col-sm-10">
                                <div style={{width: '100%'}}>
                                    <Select
                                    defaultValue={{label: item.nama_role, value: item.role}}
                                    options={selectRole}
                                    onChange={handleChangeRole}
                                    />
                                </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Ganti Logo Resi</label>
                                <div class="col-sm-10">
                                <input type="file" class="form-control" name="logoResi" id="logoResi" accept="image/jpeg, image/png" onChange={handleChangeFile} />
                                </div>
                                
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Logo Resi</label>
                                <div class="col-sm-3">
                                <img src={file ? file:item.logo_resi} alt="logo_resi" style={myImageStyle} />
                                
                                </div>
                                <div class="col-sm-3">
                                    {
                                        file ? <button type="button"class="btn btn-dark" onClick={resetFile}>Reset</button>: ''
                                    }
                                
                                </div>
                            </div>
                        
                            </React.Fragment>
                            ))}
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-10">
                                    <button type="submit" className="button_simpan" disabled={loading}>
                                        {loading ? (
                                            <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                            />
                                        ):
                                        (<i
                                            className="fa fa-save"
                                            style={{ marginRight: "5px" }}
                                        />)} &nbsp;
                                        {loading && <span>Harap Tunggu ...</span>}
                                        {!loading && <span>Simpan</span>}  
                                    </button>
                                </div>
                            </div>

                            </form>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </main>
            <Footer_admin></Footer_admin>
        </div>
  );
}

export default Edit_profile;