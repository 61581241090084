import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { add_country } from '../../../Context/actions';
import { saklar_user } from '../../../Context/actions';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';

import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

function Users() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showLogo, setShowLogo] = useState(false);
  const handleCloseLogo = () => setShowLogo(false);

  const history = useHistory();

  
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [loadingCariItem, setLoadingCariItem] = useState(false);
  const [loadingLogoResi, setLoadingLogoResi] = useState(false);
  const [loadingNonAktif, setLoadingNonAktif] = useState(false);
  const [progress, setProgress] = useState(0);
  
  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
      let url;
      const fetchData = async () => {
      if(query == ''){
          url = 'https://athaexpress.net/backend/ongkir/api/showUsersGetDataPagination/1';
          const res = await fetch(
              url,
          );
          const json = await res.json();
          
          
          if(json.status == "true"){
              setPage(page);
              setPages(json.pages);
              setRows(json.rows);
          }
          
          };

          
      }

      fetchData();
  }, [page, keyword]);

  useEffect(() => {
      let url;
      setProgress(progress + 40);
      if(query == ''){
          if(awal == undefined){
              url = 'https://athaexpress.net/backend/ongkir/api/showUsersPagination/0/1';
          }else{
              url = 'https://athaexpress.net/backend/ongkir/api/showUsersPagination/'+awal+'/1';
          }

          const fetchData = async () => {
          const res = await fetch(
              url,
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setUsersData(json.data);
          }
          
          };
          fetchData();
      }
    },[]);

  const changePage = ({ selected }) => {
      setProgress(progress + 40);
      if(query == ''){
          setPage(selected+1);
          
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);

          const fetchData = async () => {
          const res = await fetch(
              'https://athaexpress.net/backend/ongkir/api/showUsersPagination/'+awal+'/1',
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setUsersData([]);
              setUsersData(json.data);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }else{
          setPage(selected+1);
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);
          
          const fetchData = async () => {
          const res = await fetch(
              'https://athaexpress.net/backend/ongkir/api/showUsersPaginationSearch/'+awal+'/'+query+'/1',
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setUsersData([]);
              setUsersData(json.data);
              
              setPages(json.pages);
              setRows(json.rows);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }
      
  };

  function searchData(){
      setProgress(progress + 40);
      setLoadingCari(true)
      const fetchData = async () => { 
      const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/showUsersPaginationSearch/0/'+query+'/1',
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setLoadingCari(false)
          setUsersData([]);
          setPages(0);
          setRows(0);
          setPage(1);
          setUsersData(json.data);
          setPages(json.pages);
          setRows(json.rows);
          
      }else{
          setProgress(100);
          setLoadingCari(false)
          setUsersData(json.data);
          setPages(json.pages);
          setRows(json.rows);
          setMsg(
              "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Country !"
          );
      }
      
      };
      fetchData(); 
  }; 

  // useEffect(() => {
  //   setLoading(true);
  //   const fetchData = async () => {
  //     const res = await fetch(
  //       'https://athaexpress.net/backend/ongkir/api/users/1',
  //     );
  //     const json = await res.json();
     
  //     if(json.status == "true"){
  //       setLoading(false);
  //       setUsersData(json.data);
  //     }else{
  //       setLoading(false);
  //       setUsersData("Data Tidak Tersedia");
  //     }
      
  //   };
  //   fetchData();
    
  // },[]);

  useEffect(() => {
    
    setTimeout(()=>{
      $("#example2").DataTable({
        destroy: true,
        "info": false,
        //dom: "rBftlip",
        // buttons: [
        //   {
  
        //   },
        // ],
        lengthMenu: [
          [10, 20, 50, 100, -1],
          [10, 20, 50, 100, "All"],
        ],
        pageLength: 10,
    });
  
    
    },1000)

    
}, [])

  
  function edit_user(id){
    history.push('/edit_users?id='+id);
  }

  function add_user(){
    history.push('/add_users');
  }

  function lihat_logo(link){
    window.open(link);
  }

  
  function nonaktifkan_onclick(id){
    if (window.confirm('Are you sure you want to delete this ?')) {
      setProgress(progress + 40)
      setLoadingNonAktif(true)
      const promise_login = Promise.resolve(saklar_user(id));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                setProgress(100)
                setLoadingNonAktif(false)
                alert(value.message);
                history.push('/users');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setProgress(100)
                setLoadingNonAktif(false)
                alert(value.message);
                history.push('/users');
                window.location.reload(true);
            }
        });
        
    } else {
      // Do nothing!
      setProgress(100)
      setLoadingNonAktif(false)
    }
  }
  

  function resetPencarian(){
    setLoadingCari(true)
    setLoadingResetPencarian(true)
    setQuery("");
    setTimeout(
        function() {
          setLoadingCari(false)
          setLoadingResetPencarian(false)
          window.location.reload(true);
        }
        ,
        2000
    );
}

  return (
    
                <div>
                  <LoadingBar
                    color='#f11946'
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                  />
                <Header_admin></Header_admin>
              <main id="main" class="main">
  
                <div class="pagetitle">
                  <h1>Data User</h1>
                  <nav>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                      <li class="breadcrumb-item active">Users</li>
                    </ol>
                  </nav>
                </div>
  
                <section class="section">
                  <div class="row">
                    <div class="col-lg-7">
                        <button className="button_tambah_item" onClick={add_user}>
                          <i className="fa fa-plus"
                            style={{ marginRight: "5px" }}
                          />
                          &nbsp;
                          Add Users
                        </button>
                    </div>
                    <div class="col-md-5 d-flex flex-row-reverse">
                        <form class="form-inline">
                        <input
                          type="text"
                          className="form-control"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          placeholder="Cari sesuatu disini..."
                        />
                        &nbsp;
                        <button type="button" onClick={searchData} className="button_cari" disabled={loadingCari}>
                          {loadingCari ? (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          ):
                          (<i
                            className="fa fa-search"
                            style={{ marginRight: "5px" }}
                          />)}
                          {loadingCari && <span>Cari</span>}
                          {!loadingCari && <span>Cari</span>}
                        </button>
                        &nbsp;
                        <button type="button" className="button_reset_pencarian" onClick={resetPencarian} disabled={loadingCari}>
                          {loadingResetPencarian ? (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          ):
                          (<i
                            className="fa fa-refresh"
                            style={{ marginRight: "5px" }}
                          />)}
                          {loadingResetPencarian && <span>Reset Pencarian</span>}
                          {!loadingResetPencarian && <span>Reset Pencarian</span>}
                        </button>
                        </form>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-lg-12">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Nama</th>
                          <th>Alamat</th>
                          <th>Role</th>
                          <th>Akses</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        usersData != 'Data Tidak Tersedia' ?
                        usersData.map(item => (
                          
                            <tr>
                              <td>
                                {item.usercode}<br />
                                {item.flagstatus == '1' ? <div><i>Status : <b>Aktif</b></i></div>:<div><i>Status : <b>Tidak Aktif</b></i></div>}
                              </td>
                              <td>{item.nama}</td>
                              <td>{item.address}</td>
                              <td>{item.role == '1' ? 'Super User':'Admin'}</td>
                              <td>

                                    {(() => {
                                        if (item.hak_akses.split("")[0] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[0] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[0] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[0] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[0] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[0] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[0] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[0] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[0] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (item.hak_akses.split("")[1] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[1] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[1] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[1] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[1] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[1] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[1] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[1] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[1] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (item.hak_akses.split("")[2] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[2] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[2] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[2] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[2] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[2] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[2] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[2] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[2] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (item.hak_akses.split("")[3] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[3] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[3] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[3] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[3] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[3] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[3] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[3] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[3] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (item.hak_akses.split("")[4] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[4] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[4] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[4] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[4] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[4] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[4] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[4] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[4] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (item.hak_akses.split("")[5] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[5] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[5] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[5] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[5] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[5] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[5] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[5] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[5] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (item.hak_akses.split("")[6] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[6] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[6] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[6] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[6] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[6] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[6] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[6] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[6] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (item.hak_akses.split("")[7] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[7] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[7] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[7] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[7] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[7] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[7] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[7] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[7] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                     {(() => {
                                        if (item.hak_akses.split("")[8] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[8] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[8] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[8] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[8] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[8] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i><b><i>Manage User</i></b></div>
                                        )
                                        } else if (item.hak_akses.split("")[8] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[8] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[8] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (item.hak_akses.split("")[9] == '1') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Dashboard</div>
                                        )
                                        } else if (item.hak_akses.split("")[9] == '2') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Country</div>
                                        )
                                        } else if (item.hak_akses.split("")[9] == '3') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[9] == '4') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Price</div>
                                        )
                                        } else if (item.hak_akses.split("")[9] == '5') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Type Item</div>
                                        )
                                        } else if (item.hak_akses.split("")[9] == '6') {
                                        return (
                                            <div><i class="bi bi-dot"></i>User</div>
                                        )
                                        } else if (item.hak_akses.split("")[9] == '7') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Luwjistik Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[9] == '8') {
                                        return (
                                            <div><i class="bi bi-dot"></i>Aramex Order</div>
                                        )
                                        } else if (item.hak_akses.split("")[9] == '9') {
                                        return (
                                            <div><i class="bi bi-dot"></i>DHL Order</div>
                                        )
                                        }
                                    })()}
                                
                              </td>
                              <td>
                                <div class="w3-show-inline-block">
                                  <div class="w3-bar">
                                    <button type="button" class="button_edit" disabled={loading} onClick={() => edit_user(item.id)}>
                                        {loading ? (
                                          <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                          />
                                        ):
                                        (<i
                                          className="fa fa-edit"
                                          style={{ marginRight: "5px" }}
                                        />)} &nbsp;
                                        {loading && <span>Edit</span>}
                                        {!loading && <span>Edit</span>}  
                                    </button>&nbsp;
                                    <button type="button" class="button_logo_resi" disabled={loadingLogoResi} onClick={() => lihat_logo(item.logo_resi)}>
                                        {loadingLogoResi ? (
                                          <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                          />
                                        ):
                                        (<i
                                          className="fa fa-file-image-o"
                                          style={{ marginRight: "5px" }}
                                        />)} &nbsp;
                                        {loadingLogoResi && <span>Logo Resi</span>}
                                        {!loadingLogoResi && <span>Logo Resi</span>}
                                    </button>&nbsp;
                                    {
                                        item.flagstatus == '1' ? 
                                        <button type="button" class="button_api_off" disabled={loadingNonAktif} onClick={() => nonaktifkan_onclick(item.id)}>
                                          {loadingNonAktif ? (
                                            <i
                                              className="fa fa-refresh fa-spin"
                                              style={{ marginRight: "5px" }}
                                            />
                                          ):
                                          (<i
                                            className="fa fa-power-off"
                                            style={{ marginRight: "5px" }}
                                          />)} &nbsp;
                                          {loadingNonAktif && <span>Non Aktifkan</span>}
                                          {!loadingNonAktif && <span>Non Aktifkan</span>}
                                        </button> 
                                        : 
                                        <button type="button" class="button_api_on" disabled={loadingNonAktif} onClick={() => nonaktifkan_onclick(item.id)}>
                                          {loadingNonAktif ? (
                                            <i
                                              className="fa fa-refresh fa-spin"
                                              style={{ marginRight: "5px" }}
                                            />
                                          ):
                                          (<i
                                            className="fa fa-power-off"
                                            style={{ marginRight: "5px" }}
                                          />)} &nbsp;
                                          {loadingNonAktif && <span>Aktifkan</span>}
                                          {!loadingNonAktif && <span>Aktifkan</span>}
                                        </button>
                                    }   
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                          :
                          <tr>
                            <td colSpan="3" align='center'>Data Tidak Ditemukan</td>
                          </tr>
                      }
                        
                        
                      </tbody>
                    </table>
                    <p>
                          Total Rows: {rows} Page: {page} of {pages}
                      </p>
                      <p className="has-text-centered has-text-danger">{msg}</p>

                      <div id="container">
                          <ReactPaginate
                              previousLabel={"< Prev"}
                              nextLabel={"Next >"}
                              pageCount={pages}
                              onPageChange={changePage}
                              breakClassName={'page-item'}
                              breakLinkClassName={'page-link'}
                              containerClassName={'pagination'}
                              pageClassName={'page-item'}
                              pageLinkClassName={'page-link'}
                              previousClassName={'page-item'}
                              previousLinkClassName={'page-link'}
                              nextClassName={'page-item'}
                              nextLinkClassName={'page-link'}
                              activeClassName={'active'}
                          />
                      </div>
                    </div>
                  </div>
                </section>
              </main>
              <Footer_admin></Footer_admin>
                </div>
            
          
            
        
  );
}

export default Users;