import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Contact() {

  
  return(
    <div>
      <div id="header_portofolio">   
          <div class="center text-center">
            <div class="navigation_portofolio">
              <div class="nav-container">
                <div class="brand">
                  <img class="left-img" src={require('../../assets_landingpage/img/fasworld.jpg')} width="110px" height="60px" alt="" />
                </div>
                <nav class="nav__atas_portofolio">
                  <div class="nav-mobile-portofolio">
                    <a id="nav-toggle" href="#"><span></span></a>
                  </div>
                  <ul class="nav-list">
                    <li><Link to="/halaman_utama">Beranda</Link></li>
                    <li><Link to="/portofolio">Portofolio</Link></li>
                    <li><Link to="/superiority">Keunggulan</Link></li>
                    <li><Link to="/kontak">Kontak</Link></li>
                    <li><Link to="/termsandcondition">Syarat dan Ketentuan</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div> 
      </div>
      
      <div class="container_portofolio">
        
        <div class="title_portofolio">
            <h2 class="section-title text-left">Contact</h2>
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.9445155340763!2d106.83249044999907!3d-6.271027195438768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f23bce44f93f%3A0x76f2d6968ba6b110!2sJl.%20Siaga%20Swadaya%20No.63%2C%20RT.6%2FRW.4%2C%20Pejaten%20Bar.%2C%20Kecamatan%20Pasar%20MingguMinggu%2C%20Kota%20Jakarta%20Selatan%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2012510!5e0!3m2!1sid!2sid!4v1662049362678!5m2!1sid!2sid" height="250px"></iframe>
                    <div class="card-body">
                    <h5 class="card-title">Jakarta</h5>
                    <p class="card-text"><b><i class="bi bi-geo-alt-fill"></i></b>&nbsp;&nbsp;Jl Siaga Swadaya No 63 Pejaten Barat</p>
                    </div>
                </div>
                <div class="card">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.9445155340763!2d106.83249044999907!3d-6.271027195438768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f23bce44f93f%3A0x76f2d6968ba6b110!2sJl.%20Siaga%20Swadaya%20No.63%2C%20RT.6%2FRW.4%2C%20Pejaten%20Bar.%2C%20Kecamatan%20Pasar%20MingguMinggu%2C%20Kota%20Jakarta%20Selatan%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2012510!5e0!3m2!1sid!2sid!4v1662049362678!5m2!1sid!2sid" height="250px"></iframe>
                    <div class="card-body">
                    <h5 class="card-title">Semarang</h5>
                    <p class="card-text"><b><i class="bi bi-geo-alt-fill"></i></b>&nbsp;&nbsp;West Giri Mukti Park I No 351A</p>
                    </div>
                </div>
                <div class="card">  
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.3312482207984!2d112.69520578149334!3d-7.31663658035268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fc8ef0c214e9%3A0xfeb4e3b11a24d7bb!2sJl.%20Wiyung%20Indah%20XVIII%20Blok%20kk%20No.16%2C%20Jajar%20Tunggal%2C%20Kec.%20Wiyung%2C%20Kota%20SBY%2C%20Jawa%20Timur%2060229!5e0!3m2!1sid!2sid!4v1662049603894!5m2!1sid!2sid" height="250px"></iframe>
                    <div class="card-body">
                    <h5 class="card-title">Surabaya</h5>
                    <p class="card-text"><b><i class="bi bi-geo-alt-fill"></i></b>&nbsp;&nbsp;Beautiful Wiyung XVIII No 16 A</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div id="footer_portofolio" class="dark-wrapper-portofolio">
          <div class="container inner">
              <div class="row logo_footer">
                  <div class="col-sm-12">
                    Fast World Express
                  </div>
              </div>
              <div class="row alamat">
                  <div class="col-sm-12">
                    Wiyung Indah XVIII NO 16 A,<br />
                    Jawa Timur, Kota Surabaya, Kecamatan Wiyung.
                  </div>
              </div>
              <div class="row social">
                  <div class="col-sm-12">
                      <div class="social-bar">
                          <a href="#" class="fa fa-instagram tooltipped" title="instagram"></a>
                          <a href="#" class="fa fa-youtube-square tooltipped" title="youtube"></a>
                          <a href="#" class="fa fa-facebook-square tooltipped" title="facebook"></a>
                          <a href="#" class="fa fa-twitter-square tooltipped" title="twitter"></a>
                          <a href="#" class="fa fa-google-plus-square tooltipped" title="google+"></a>
                      </div>
                  </div>
              </div>
              <div class="row copyright">
                  <div class="col-sm-12">
                      &copy; Copyright Fast World Express 2022
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}