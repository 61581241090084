import React, {useEffect, useState} from 'react';
import { Link  } from 'react-router-dom';
import { useHistory  } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from "react-select";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';


import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

function Invoice_due_date_report() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseSnackbar = () => setAlert(false);

  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [progress, setProgress] = useState(0)

  const history = useHistory();

  const [driverName, setDriverName] = useState();
  const [driverAddress, setDriverAddress] = useState();
  const [driverPhone, setDriverPhone] = useState();
  


  const [msg, setMsg] = useState("");

  
  const [idCustomer, setIdCustomer] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState([]);
  const [fromDate, setFromDate] = useState(['2000-01-01']);
  const [untilDate, setUntilDate] = useState(['2000-01-01']);
  const [awal, setAwal] = useState("");
  const [akhir, setAkhir] = useState("");
  const [driver, setDriver] = useState("");
  const [customer, setCustomer] = useState("");
  const [recapOrder, setRecapOrder] = useState([]);

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');


  const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'usercode': localStorage.getItem('username'),
          'token': localStorage.getItem('token'),
      }
  };

  const tableCustomStyles = {
    headRow: {
      style: {
        color:'#223336',
        backgroundColor: '#e7eef0'
      },
    },
    rows: {
      style: {
        color: "STRIPEDCOLOR",
        backgroundColor: "STRIPEDCOLOR"
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "NORMALCOLOR"
      }
    }
  }

  useEffect(() => {

    setAlert(true);
    setAlertContent("Load data Invoice ...");

    const fetchData = async () => { 
    const res = await fetch(
        'https://revasamudra.ivantie.com/backend/revasamudra/api/invoiceDueDateReport',settings
    );
    const json = await res.json();

    console.log(json);

    setProgress(100);
    setLoadingCari(false)
    setRecapOrder(json);
    setAlert(false);
    };
    fetchData(); 
 }, []);


  return (
      <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        /> 
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
                {alertContent}
                </Alert>
        </Snackbar>
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Data Invoice Jatuh Tempo</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Lap Invoice Jatuh Tempo</li>
              </ol>
            </nav>
          </div>

          <section class="section">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                    <DataTableExtensions {...recapOrder}>
                      <DataTable
                          noHeader
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          highlightOnHover
                          customStyles={tableCustomStyles}
                      />
                    </DataTableExtensions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </main>

        <Footer_admin></Footer_admin>

      </div>
  );
}

export default Invoice_due_date_report;