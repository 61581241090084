import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Superiority() {

    console.log(window.location.origin);
  
  return(
    <div>
      <div id="header_portofolio">    
          <div class="center text-center">
            <div class="navigation_portofolio">
              <div class="nav-container">
                <div class="brand">
                  <img class="left-img" src={require('../../assets_landingpage/img/fasworld.jpg')} width="110px" height="60px" alt="" />
                </div>
                <nav class="nav__atas_portofolio">
                  <div class="nav-mobile-portofolio">
                    <a id="nav-toggle" href="#"><span></span></a>
                  </div>
                  <ul class="nav-list">
                    <li><Link to="/halaman_utama">Beranda</Link></li>
                    <li><Link to="/portofolio">Portofolio</Link></li>
                    <li><Link to="/superiority">Keunggulan</Link></li>
                    <li><Link to="/kontak">Kontak</Link></li>
                    <li><Link to="/termsandcondition">Syarat dan Ketentuan</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
      </div>
      
      <div class="container_portofolio">
        
        <div class="title_portofolio">
            <h2 class="section-title text-left">Advantages of Cheap Express Delivery of Goods and Documents Overseas Fast World Express</h2>
            <p class="lead main text-left">
            We have several distinct advantages, when compared to other overseas delivery services (packages) and documents. For example: about ability, experience, knowledge of customs, and networking. Furthermore, our overseas shipping speed is very fast (lightning) with an average ETD (time of arrival) of 2-5 days. Then, our overseas shipping rates are also cheaper and more economical. Because, the calculation of rates, starting from 1/2 kg and multiples thereof.</p>

            <p class="lead main text-left">In addition, we also have air cargo services (airfreight) that other overseas shipping services do not have. Not only that, especially for cargo services, the time to arrive at the airport of the destination country is 1-2 days. In fact, we can do it on the same day (until the same day).</p>   

            <p class="lead main text-left">However, among all that, there are 3 (three) important advantages as our Main Focus. Among others, namely: Speed, Security and Service. Or, in English our motto is “3S” is our business. The first is Speed. Second, Safe. And third, namely Service.</p>
        
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/speed.png')} height="300px" alt="Port 1" />
                    <div class="card-body">
                    <h5 class="card-title">Speed</h5>
                    <p class="card-text">Speed ​​of delivery of packages and customer documents is our main focus</p>
                    </div>
                </div>
                <div class="card">
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/security.png')} height="300px" alt="Port 2" />
                    <div class="card-body">
                    <h5 class="card-title">Security</h5>
                    <p class="card-text">The security of customer packages and documents is our top priority</p>
                    </div>
                </div>
                <div class="card">  
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/services.png')} height="300px" alt="Port 3" />
                    <div class="card-body">
                    <h5 class="card-title">Service</h5>
                    <p class="card-text">Excellent service and service for customers is the most important thing for us</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div id="footer_portofolio" class="dark-wrapper-portofolio">
          <div class="container inner">
              <div class="row logo_footer">
                  <div class="col-sm-12">
                    Fast World Express
                  </div>
              </div>
              <div class="row alamat">
                  <div class="col-sm-12">
                    Wiyung Indah XVIII NO 16 A,<br />
                    Jawa Timur, Kota Surabaya, Kecamatan Wiyung.
                  </div>
              </div>
              <div class="row social">
                  <div class="col-sm-12">
                      <div class="social-bar">
                          <a href="#" class="fa fa-instagram tooltipped" title="instagram"></a>
                          <a href="#" class="fa fa-youtube-square tooltipped" title="youtube"></a>
                          <a href="#" class="fa fa-facebook-square tooltipped" title="facebook"></a>
                          <a href="#" class="fa fa-twitter-square tooltipped" title="twitter"></a>
                          <a href="#" class="fa fa-google-plus-square tooltipped" title="google+"></a>
                      </div>
                  </div>
              </div>
              <div class="row copyright">
                  <div class="col-sm-12">
                      &copy; Copyright Fast World Express 2022
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}