import axios from 'axios';


export const isAuthenticated = async () => {
  let hasil,message,hakakses;
  const username = localStorage.getItem('username');
  const token = localStorage.getItem('token');

  if(username == "" || token == ""){
    hasil = '2';
    message = "Auth gagal";
    hakakses = "0";
  }else{
    const formData = new FormData();

    formData.append('username', username);
    formData.append('token', token);

    await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/auth', formData)
    .then((response) => {

      if(response.data.status == true){
        //jika token sesuai
        const username = localStorage.getItem('username');
        hasil = '1';
        message = username;
        hakakses = response.data.hak_akses;
      }else if(response.data.status == false){
        //jika token tidak sesuai
        hasil = '2';
        message = response.data.message;
        hakakses = response.data.hak_akses;
      }
    })
    .catch((error) => {
        //jika axios error
        hasil = '3';
        message = error;
        hakakses = "0";
    })
  }

  return {
    hasil: hasil,
    message: message,
    hakakses: hakakses,
  };
  
};

export const login = async (username, password) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('username', username);
  formData.append('password', password);

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/login', formData)
  .then((response) => {

      if(response.data.status == true){
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', username);
        localStorage.setItem('rl', response.data.role);
        localStorage.setItem('nama', response.data.message);
        
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '3';
      message = error;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const logout = async (username, token) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('username', username);
  formData.append('token', token);

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/logout', formData)
  .then((response) => {

    
      
    if(response.data.status == true){
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('nama');
      
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
      
  })
  .catch((error) => {
    hasil = '3';
    message = error;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_customer = async (customerName,customerAddress,customerPhone,customerContactPerson) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('customerName', customerName);
  formData.append('customerAddress', customerAddress);
  formData.append('customerPhone', customerPhone);
  formData.append('customerContactPerson', customerContactPerson);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/addCustomerProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_customer = async (id,customerCode,customerName,customerAddress,customerPhone,customerContactPerson) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('customerCode', customerCode);
  formData.append('customerName', customerName);
  formData.append('customerAddress', customerAddress);
  formData.append('customerPhone', customerPhone);
  formData.append('customerContactPerson', customerContactPerson);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/editCustomerProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_customer = async (id) => {
  let hasil,message;

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteCustomer/'+id, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_item = async (itemName,itemUnitName,itemStock,itemCategory,itemBrand) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('itemName', itemName);
  formData.append('itemUnitName', itemUnitName);
  formData.append('itemStock', itemStock);
  formData.append('itemCategory', itemCategory);
  formData.append('itemBrand', itemBrand);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/addItemProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_item = async (id,itemCode,itemName,itemUnitName,itemStock,itemCategory,itemBrand) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('itemCode', itemCode);
  formData.append('itemName', itemName);
  formData.append('itemUnitName', itemUnitName);
  formData.append('itemStock', itemStock);
  formData.append('itemCategory', itemCategory);
  formData.append('itemBrand', itemBrand);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/editItemProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_item = async (id) => {
  let hasil,message;

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteItem/'+id, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_fleet = async (fleetNumberPlate,fleetNote,fleetOwnership) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('numberPlate', fleetNumberPlate);
  formData.append('note', fleetNote);
  formData.append('ownership', fleetOwnership);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/addFleetProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_fleet = async (id,fleetNumberPlate,fleetNote,fleetOwnership) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('numberPlate', fleetNumberPlate);
  formData.append('note', fleetNote);
  formData.append('ownership', fleetOwnership);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/editFleetProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_fleet = async (id) => {
  let hasil,message;

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteFleet/'+id, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_driver = async (driverName,driverAddress,driverPhone) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('driverName', driverName);
  formData.append('driverAddress', driverAddress);
  formData.append('driverPhone', driverPhone);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/addDriverProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_driver = async (id,driverCode,driverName,driverAddress,driverPhone) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('driverCode', driverCode);
  formData.append('driverName', driverName);
  formData.append('driverAddress', driverAddress);
  formData.append('driverPhone', driverPhone);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/editDriverProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_driver = async (id) => {
  let hasil,message;

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteDriver/'+id, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_supplier = async (supplierName,supplierAddress,supplierPhone) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('supplierName', supplierName);
  formData.append('supplierAddress', supplierAddress);
  formData.append('supplierPhone', supplierPhone);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/addSupplierProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_supplier = async (id,supplierCode,supplierName,supplierAddress,supplierPhone) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('supplierCode', supplierCode);
  formData.append('supplierName', supplierName);
  formData.append('supplierAddress', supplierAddress);
  formData.append('supplierPhone', supplierPhone);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/editSupplierProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_supplier = async (id) => {
  let hasil,message;

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteSupplier/'+id, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_delivery_destination = async (deliveryDestinationName,deliveryDestinationAddress,deliveryDestinationPhone) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('deliveryDestinationName', deliveryDestinationName);
  formData.append('deliveryDestinationAddress', deliveryDestinationAddress);
  formData.append('deliveryDestinationPhone', deliveryDestinationPhone);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/addDeliveryDestinationProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_delivery_destination = async (id,deliveryDestinationCode,deliveryDestinationName,deliveryDestinationAddress,deliveryDestinationPhone) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('deliveryDestinationCode', deliveryDestinationCode);
  formData.append('deliveryDestinationName', deliveryDestinationName);
  formData.append('deliveryDestinationAddress', deliveryDestinationAddress);
  formData.append('deliveryDestinationPhone', deliveryDestinationPhone);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/editDeliveryDestinationProcess', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_delivery_destination = async (id) => {
  let hasil,message;

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteDeliveryDestination/'+id, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const create_rent = async (invoiceNumber,invoiceDate,customerCode,customerName,note,pph,downpaymentMoney,totalPrice,pay,pphType,tempo,pphMoney,fee,totalPocketMoney,totalOtherMoney,totalStapelMoney,totalStapelPocketMoney,totalHarborMoney,totalDepoMoney,totalCoollieMoney,totalOvertimeMoney,rowsData) => {

  let hasil,message;
  const formData = new FormData();

  formData.append('invoiceNumber', invoiceNumber);
	formData.append('invoiceDate', invoiceDate);
  formData.append('customerCode', customerCode);
  formData.append('customerName', customerName);
  formData.append('note', note);
  formData.append('pph', pph);
  formData.append('downpaymentMoney', downpaymentMoney);
  formData.append('totalPrice', totalPrice);
  formData.append('pay', pay);
  formData.append('pphType', pphType);
  formData.append('tempo', tempo);
  formData.append('pphMoney', pphMoney);
  formData.append('fee', fee);
  formData.append('totalPocketMoney', totalPocketMoney);
  formData.append('totalOtherMoney', totalOtherMoney);
  formData.append('totalStapelMoney', totalStapelMoney);
  formData.append('totalStapelPocketMoney', totalStapelPocketMoney);
  formData.append('totalHarborMoney', totalHarborMoney);
  formData.append('totalDepoMoney', totalDepoMoney);
  formData.append('totalCoollieMoney', totalCoollieMoney);
  formData.append('totalOvertimeMoney', totalOvertimeMoney);
  formData.append('userCode', localStorage.getItem('username'));
  formData.append('rowsData', JSON.stringify(rowsData));
  // formData.append('idPrice', idPrice);
  // formData.append('namaTypeItem', namaTypeItem);

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/addRentProcess', formData, {
    headers: headers
  })
  .then((response) => {

    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
    message = response.data.message;
  })
  .catch((error) => {
      hasil = '3';
      message = error;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const edit_rent = async (invoiceNumber,invoiceDate,customerCode,customerName,note,pph,downpaymentMoney,totalPrice,pay,pphType,tempo,pphMoney,fee,totalPocketMoney,totalOtherMoney,totalStapelMoney,totalStapelPocketMoney,totalHarborMoney,totalDepoMoney,totalCoollieMoney,totalOvertimeMoney,rowsData) => {

  let hasil,message;
  const formData = new FormData();

	formData.append('invoiceNumber', invoiceNumber);
  formData.append('invoiceDate', invoiceDate);
  formData.append('customerCode', customerCode);
  formData.append('customerName', customerName);
  formData.append('note', note);
  formData.append('pph', pph);
  formData.append('downpaymentMoney', downpaymentMoney);
  formData.append('totalPrice', totalPrice);
  formData.append('pay', pay);
  formData.append('pphType', pphType);
  formData.append('tempo', tempo);
  formData.append('pphMoney', pphMoney);
  formData.append('fee', fee);
  formData.append('totalPocketMoney', totalPocketMoney);
  formData.append('totalOtherMoney', totalOtherMoney);
  formData.append('totalStapelMoney', totalStapelMoney);
  formData.append('totalStapelPocketMoney', totalStapelPocketMoney);
  formData.append('totalHarborMoney', totalHarborMoney);
  formData.append('totalDepoMoney', totalDepoMoney);
  formData.append('totalCoollieMoney', totalCoollieMoney);
  formData.append('totalOvertimeMoney', totalOvertimeMoney);
  formData.append('userCode', localStorage.getItem('username'));
  formData.append('rowsData', JSON.stringify(rowsData));
  // formData.append('idPrice', idPrice);
  // formData.append('namaTypeItem', namaTypeItem);

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/editRentProcess', formData, {
    headers: headers
  })
  .then((response) => {

    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }

  })
  .catch((error) => {
      hasil = '3';
      message = error.response.data;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const pay_rent = async (invoiceNumber,payPrice) => {
  let hasil,message;
  const formData = new FormData();

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/payRent/'+ invoiceNumber + '/' + payPrice, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '3';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_rent = async (id) => {
  let hasil,message;
  const formData = new FormData();

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteRent/'+ id, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '3';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_detail_rent = async (id,invoiceNumber) => {
  let hasil,message;

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteDetailRent/'+ id + '/' + invoiceNumber, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == '1'){
       
        hasil = '1';
        message = response.data.message;
      }else if(response.data.status == '2'){
        hasil = '2';
        message = response.data.message;
      }else if(response.data.status == '3'){
        hasil = '3';
        message = response.data.message;
      }else if(response.data.status == '4'){
        hasil = '4';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '5';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const upload_rent = async (invoiceNumber,linkTt) => {
  let hasil,message;
  const formData = new FormData();

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

  formData.append('invoiceNumber', invoiceNumber);
  formData.append('linkTt', linkTt);

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/uploadFoto', formData,{
    headers: headers
  })
  .then((response) => {
      //console.log(response);
      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      //console.log(error.message);
      hasil = '3';
      message = error;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const create_order_recap = async (dateOrderRecap,customerCode,customerName,numberPlate,driverCode,driverName,from,destination,activity,containerSize,deliveryDestinationCode,deliveryDestinationName,containerNumber,pocketMoney,coolieMoney,otherMoney,billMoney,note,downpaymentMoney,harbor,harborMoney,stapelMoney,depo,depoMoney,stapelPocketMoney,overtimeMoney) => {

  let hasil,message;
  const formData = new FormData();

	formData.append('dateOrderRecap', dateOrderRecap);
  formData.append('customerCode', customerCode);
  formData.append('customerName', customerName);
  formData.append('numberPlate', numberPlate);
  formData.append('driverCode', driverCode);
  formData.append('driverName', driverName);
  formData.append('from', from);
  formData.append('destination', destination);
  formData.append('activity', activity);
  formData.append('containerSize', containerSize);
  formData.append('deliveryDestinationCode', deliveryDestinationCode);
  formData.append('deliveryDestinationName', deliveryDestinationName);
  formData.append('containerNumber', containerNumber);
  formData.append('pocketMoney', pocketMoney);
  formData.append('coolieMoney', coolieMoney);
  formData.append('otherMoney', otherMoney);
  formData.append('billMoney', billMoney);
  formData.append('note', note);
  formData.append('downpaymentMoney', downpaymentMoney);
  formData.append('harbor', harbor);
  formData.append('harborMoney', harborMoney);
  formData.append('stapelMoney', stapelMoney);
  formData.append('depo', depo);
  formData.append('depoMoney', depoMoney);
  formData.append('stapelPocketMoney', stapelPocketMoney);
  formData.append('overtimeMoney', overtimeMoney);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/addOrderRecapProcess', formData, {
    headers: headers
  })
  .then((response) => {

    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const edit_order_recap = async (id,orderRecapCode,dateOrderRecap,customerCode,customerName,numberPlate,driverCode,driverName,from,destination,activity,containerSize,deliveryDestinationCode,deliveryDestinationName,containerNumber,pocketMoney,coolieMoney,otherMoney,billMoney,note,downpaymentMoney,harbor,harborMoney,stapelMoney,depo,depoMoney,stapelPocketMoney,overtimeMoney) => {

  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
	formData.append('orderRecapCode', orderRecapCode);
  formData.append('dateOrderRecap', dateOrderRecap);
  formData.append('customerCode', customerCode);
  formData.append('customerName', customerName);
  formData.append('numberPlate', numberPlate);
  formData.append('driverCode', driverCode);
  formData.append('driverName', driverName);
  formData.append('from', from);
  formData.append('destination', destination);
  formData.append('activity', activity);
  formData.append('containerSize', containerSize);
  formData.append('deliveryDestinationCode', deliveryDestinationCode);
  formData.append('deliveryDestinationName', deliveryDestinationName);
  formData.append('containerNumber', containerNumber);
  formData.append('containerNumber', containerNumber);
  formData.append('pocketMoney', pocketMoney);
  formData.append('coolieMoney', coolieMoney);
  formData.append('otherMoney', otherMoney);
  formData.append('billMoney', billMoney);
  formData.append('note', note);
  formData.append('downpaymentMoney', downpaymentMoney);
  formData.append('harbor', harbor);
  formData.append('harborMoney', harborMoney);
  formData.append('stapelMoney', stapelMoney);
  formData.append('depo', depo);
  formData.append('depoMoney', depoMoney);
  formData.append('stapelPocketMoney', stapelPocketMoney);
  formData.append('overtimeMoney', overtimeMoney);
  formData.append('userCode', localStorage.getItem('username'));

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/editOrderRecapProcess', formData, {
    headers: headers
  })
  .then((response) => {
    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {
      hasil = '3';
      message = error.response.data;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const delete_order_recap = async (id) => {
  let hasil,message;

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/deleteOrderRecap/'+id, {
    headers: headers
  })
  .then((response) => {
      if(response.data.status == 'true'){
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '3';
      message = error;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const invoice = async (id) => {

  let hasil,message;

	await axios.get('https://revasamudra.ivantie.com/backend/revasamudra/api/invoice/'+id)
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};

export const change_password = async (username,oldpassword,newpassword) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('username', username);
  formData.append('oldpassword', oldpassword);
	formData.append('newpassword', newpassword);

  const headers = {
    'Content-Type': 'application/json',
    'usercode': localStorage.getItem('username'),
    'token': localStorage.getItem('token'),
  }

	await axios.post('https://revasamudra.ivantie.com/backend/revasamudra/api/changePassword', formData, {
    headers: headers
  })
  .then((response) => {

      if(response.data.status == 'true'){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '3';
      message = error;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const saklar_user = async (id) => {

  let hasil,message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/saklar_user/'+id)
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};

export const edit_profile = async (parUsername,username,name,address,role,akses,logoresi) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('parusername', parUsername);
  formData.append('usercode', username);
  formData.append('name', name);
  formData.append('address', address);
  formData.append('role', role);
  formData.append('hakakses', akses);
  formData.append('logoresi', logoresi);
  formData.append('changeby', localStorage.getItem('username'));

	await axios.post('https://athaexpress.net/backend/ongkir/api/editProfileProcess', formData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then((response) => {
      //console.log(response);
      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      //console.log(error.message);
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_user = async (id,username,name,address,role,akses,logoresi) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('usercode', username);
  formData.append('name', name);
  formData.append('address', address);
  formData.append('role', role);
  formData.append('hakakses', akses);
  formData.append('logoresi', logoresi);
  formData.append('changeby', localStorage.getItem('username'));

	await axios.post('https://athaexpress.net/backend/ongkir/api/editUserProcess', formData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then((response) => {
      //console.log(response);
      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      //console.log(error.message);
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_user = async (username,password,name,address,role,akses,logoresi) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('usercode', username);
  formData.append('password', password);
  formData.append('name', name);
  formData.append('address', address);
  formData.append('role', role);
  formData.append('hakakses', akses);
  formData.append('logoresi', logoresi);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addUserProcess', formData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then((response) => {
      //console.log(response);
      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      //console.log(error.message);
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};