import React, {useState,useEffect} from 'react';
import { useHistory } from 'react-router';
import { logout,isAuthenticated } from '../Context/actions';
import {TimeoutLogic} from "../Context/TimeoutLogic";
import { Link } from 'react-router-dom';



function Header_admin() {
  const history = useHistory();

  let [nama, setNama] = useState('0');
  const [dashboard, setDashboard] = useState(false);
  const [pelanggan, setPelanggan] = useState(false);
  const [armada, setArmada] = useState(false);
  const [sopir, setSopir] = useState(false);
  const [barang, setBarang] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [pabrik, setPabrik] = useState(false);
  const [sewa, setSewa] = useState(false);
  const [rekapOrder, setRekapOrder] = useState(false);
  const [pencarianHarga, setPencarianHarga] = useState(false);
  const [penggunaan, setPenggunaan] = useState(false);
  const [pembelian, setPembelian] = useState(false);
  const [perawatan, setPerawatan] = useState(false);
  const [lainlain, setLainLain] = useState(false);
  const [pembayaranInvoice, setPembayaranInvoice] = useState(false);
  const [cetakInvoice, setCetakInvoice] = useState(false);
  const [lihatInvoiceJatuhTempo, setLihatInvoiceJatuhTempo] = useState(false);
  const [lihatInvoiceBelumLunas, setLihatInvoiceBelumLunas] = useState(false);
  const [lihatInvoiceLunas, setLihatInvoiceLunas] = useState(false);
  const [lihatPembayaranMobilOrangLain, setLihatPembayaranMobilOrangLain] = useState(false);
  const [lihatPenggunaan, setLihatPenggunaan] = useState(false);
  const [lihatStokDiMobil, setLihatStokDiMobil] = useState(false);
  const [lihatPembelianBelumLunas, setLihatPembelianBelumLunas] = useState(false);
  const [listRekapOrder, setListRekapOrder] = useState(false);
  const [laporanTotalanSopir, setLaporanTotalanSopir] = useState(false);
  const [laporanLabaRugi, setLaporanLabaRugi] = useState(false);
  const [laporanRekapOrder, setLaporanRekapOrder] = useState(false);
  const [laporanPerawatan, setLaporanPerawatan] = useState(false);
  const [laporanLainLain, setLaporanLainLain] = useState(false);
  const [laporanPembelian, setLaporanPembelian] = useState(false);
  const [laporanInvoice, setLaporanInvoice] = useState(false);
  const [laporanInvoiceBlmLunas, setLaporanInvoiceBlmLunas] = useState(false);
  const [users, setUsers] = useState(false);

  const [count, setCount] = useState(0);
  const [totalData, setTotalData] = useState("");
  const [countInTimeout, setCountInTimeout] = useState(0);

  const [progress, setProgress] = useState(0);

  // console.log = console.warn = console.error = () => {};
  // console.error('Something bad happened.');
  
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }


  function teste(){
      select('body').classList.toggle('toggle-sidebar')
  }

  function keluar(){
    // setProgress(progress + 40)
    const promise1 = Promise.resolve(logout(localStorage.getItem('username'),localStorage.getItem('token')));
    promise1.then((value) => {
      //console.log(value.hasil);
      
        if(value.hasil == '1'){
            //sukses
            setNama('0');
            history.push("/admin");
            window.location.reload(true);
        }else if(value.hasil == '2'){
            //gagal
            alert(value.message);
            //history.push("/admin");
        }else if(value.hasil == '3'){
            //axios error
            alert(value.message);
            //history.push("/admin");
        }
    });
  }

  const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'usercode': localStorage.getItem('username'),
          'token': localStorage.getItem('token'),
      }
  };

  useEffect(() => {

    const fetchData = async () => { 
    const res = await fetch(
        'https://revasamudra.ivantie.com/backend/revasamudra/api/invoiceDueDateView',settings
    );
    const json = await res.json();
    if(json.status == true){
        setTotalData(json.totalData);
    }else{
      setTotalData(0);
    }
    
    };
    fetchData(); 
 }, []);

  if(nama == '0'){
    const promise_auth = Promise.resolve(isAuthenticated());
    promise_auth.then((value) => {
        if(value.hasil == '1'){
            //sukses
            setNama(value.message);
            if(value.hakakses.split(",")[0] == "1"){
              setDashboard(true);
            }
            if(value.hakakses.split(",")[1] == "2"){
              setPelanggan(true);
            }
            if(value.hakakses.split(",")[2] == "3"){
              setArmada(true);
            }
            if(value.hakakses.split(",")[3] == "4"){
              setSopir(true);
            }
            if(value.hakakses.split(",")[4] == "5"){
              setBarang(true);
            }
            if(value.hakakses.split(",")[5] == "6"){
              setSupplier(true);
            }
            if(value.hakakses.split(",")[6] == "7"){
              setPabrik(true);
            }
            if(value.hakakses.split(",")[7] == "8"){
              setRekapOrder(true);
            }
            if(value.hakakses.split(",")[8] == "9"){
              setPencarianHarga(true);
            }
            if(value.hakakses.split(",")[9] == "10"){
              setSewa(true);
            }
            if(value.hakakses.split(",")[10] == "11"){
              setPenggunaan(true);
            }
            if(value.hakakses.split(",")[11] == "12"){
              setPembelian(true);
            }
            if(value.hakakses.split(",")[12] == "13"){
              setPerawatan(true);
            }
            if(value.hakakses.split(",")[13] == "14"){
              setLainLain(true);
            }
            if(value.hakakses.split(",")[14] == "15"){
              setCetakInvoice(true);
            }
            if(value.hakakses.split(",")[15] == "16"){
              setLihatInvoiceBelumLunas(true);
            }
            if(value.hakakses.split(",")[16] == "17"){
              setLihatInvoiceLunas(true);
            }
            if(value.hakakses.split(",")[17] == "18"){
              setLihatPembayaranMobilOrangLain(true);
            }
            if(value.hakakses.split(",")[18] == "19"){
              setLihatPenggunaan(true);
            }
            if(value.hakakses.split(",")[19] == "20"){
              setLihatStokDiMobil(true);
            }
            if(value.hakakses.split(",")[20] == "21"){
              setLihatPembelianBelumLunas(true);
            }
            if(value.hakakses.split(",")[21] == "22"){
              setListRekapOrder(true);
            }
            if(value.hakakses.split(",")[22] == "23"){
              setLaporanTotalanSopir(true);
            }
            if(value.hakakses.split(",")[23] == "24"){
              setLaporanLabaRugi(true);
            }
            if(value.hakakses.split(",")[24] == "25"){
              setLaporanRekapOrder(true);
            }
            if(value.hakakses.split(",")[25] == "26"){
              setLaporanPerawatan(true);
            }
            if(value.hakakses.split(",")[26] == "27"){
              setLaporanLainLain(true);
            }
            if(value.hakakses.split(",")[27] == "28"){
              setLaporanPembelian(true);
            }
            if(value.hakakses.split(",")[28] == "29"){
              setLaporanInvoice(true);
            }
            if(value.hakakses.split(",")[29] == "30"){
              setUsers(true);
            }
            if(value.hakakses.split(",")[30] == "31"){
              setLihatInvoiceJatuhTempo(true);
            }
            if(value.hakakses.split(",")[31] == "32"){
              setPembayaranInvoice(true);
            }
            if(value.hakakses.split(",")[32] == "33"){
              setLaporanInvoiceBlmLunas(true);
            }
        }else{
            //tidak sukses
            console.log(value.message);
            localStorage.setItem('token', '');
            localStorage.setItem('username', '');
            localStorage.setItem('nama', '');
            setNama('0');
            history.push("/admin");
            window.location.reload(true);
        }
    },[]);
  }

  


  

  
  

        return (
          <div>
            <TimeoutLogic/>
            <header id="header" class="header fixed-top d-flex align-items-center">

            <div class="d-flex align-items-center justify-content-between">
           
              <img src={require('../assets_landingpage/img/revasamudra.png')} alt="" style={{width: "50px", height: "50px"}} />
              <h3 class="mt-2">Reva Samudra</h3>
              <i class="bi bi-list toggle-sidebar-btn" onClick={teste}></i>
            </div>
        
        
            {/* <nav class="header-nav ms-auto">
              <ul class="d-flex align-items-center">
        
            
        
                <li class="nav-item dropdown pe-3">
        
                  <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                    <img src={require('../assets_admin_new/img/profile-img.jpg')} alt="Profile" class="rounded-circle" />
                    <span class="d-none d-md-block dropdown-toggle ps-2">{nama}</span>
                  </a>
        
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                    <li class="dropdown-header">
                      <h6>{nama}</h6>
                      <span>Operator</span>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
        
                    <li>
                      <Link class="nav-link" to="/profile">
                        <i class="bi bi-person"></i>&nbsp;&nbsp;
                        <span>Profil</span>
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
        
                    <li>
                      <Link class="nav-link" to="/changepassword">
                        <i class="bi bi-gear"></i>&nbsp;&nbsp;
                        <span>Ganti Password</span>
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
        
                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" onClick={keluar}>
                        <i class="bi bi-box-arrow-right"></i>
                        <span><b>Keluar</b></span>
                      </a>
                    </li>
        
                  </ul>
                </li>
        
              </ul>
            </nav> */}
        
          </header>
          <aside id="sidebar" class="sidebar">

            <ul class="sidebar-nav" id="sidebar-nav">

              <li class="nav-item" style={{display: dashboard ? 'block' : 'none' }}>
                <Link class="nav-link" to="/dashboard">
                  <i class="bi bi-grid" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Dashboard</span>
                </Link>
              </li>

              <li class="nav-heading" style={{display: pelanggan || armada || sopir || barang || supplier || pabrik ? 'block' : 'none' }}>Master</li>

              <li class="nav-item" style={{display: pelanggan ? 'block' : 'none' }}>
                <Link class="nav-link" to="/customer">
                  <i class="bi bi-person-heart" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Pelanggan</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: sopir ? 'block' : 'none' }}>
                <Link class="nav-link" to="/driver">
                  <i class="bi bi-people-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Sopir</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: armada ? 'block' : 'none' }}>
                <Link class="nav-link" to="/fleet">
                  <i class="bi bi-car-front" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Armada</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: barang ? 'block' : 'none' }}>
                <Link class="nav-link" to="/item">
                  <i class="bi bi-hammer" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Barang</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: supplier ? 'block' : 'none' }}>
                <Link class="nav-link" to="/supplier">
                  <i class="bi bi-person-lines-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Supplier</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: pabrik ? 'block' : 'none' }}>
                <Link class="nav-link" to="/delivery_destination">
                  <i class="bi bi-house-heart-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Pabrik</span>
                </Link>
              </li>

              <li class="nav-heading" style={{display: rekapOrder || pencarianHarga || sewa || penggunaan || pembelian || perawatan || lainlain || pembayaranInvoice ? 'block' : 'none' }}>Transaksi</li>

              <li class="nav-item" style={{display: rekapOrder ? 'block' : 'none' }}>
                <Link class="nav-link" to="/order_recap">
                  <i class="bi bi-file-earmark-richtext" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Rekap Order</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: pencarianHarga ? 'block' : 'none' }}>
                <Link class="nav-link" to="/search_price">
                  <i class="bi bi-search" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Pencarian Harga</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: sewa ? 'block' : 'none' }}>
                <Link class="nav-link" to="/rent">
                  <i class="bi bi-globe" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Sewa</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: pembayaranInvoice ? 'block' : 'none' }}>
                <Link class="nav-link" to="/pay_rent">
                  <i class="bi bi-currency-yen" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Pembayaran Invoice</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: penggunaan ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                <i class="bi bi-puzzle-fill" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Penggunaan</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: pembelian ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-tag-fill" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Pembelian</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: perawatan ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-thermometer-low" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Perawatan</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: lainlain ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-wind" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lain-Lain</span>
                </Link>
              </li>

              <li class="nav-heading" style={{display: cetakInvoice ? 'block' : 'none' }}>Invoice</li>

              <li class="nav-item" style={{display: cetakInvoice ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-file-earmark-binary-fill" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Cetak Invoice</span>
                </Link>
              </li>

              <li class="nav-heading" style={{display: lihatInvoiceBelumLunas || lihatInvoiceLunas || lihatPembayaranMobilOrangLain || lihatPenggunaan || lihatStokDiMobil || lihatPembelianBelumLunas || listRekapOrder || lihatInvoiceJatuhTempo ? 'block' : 'none' }}>Lihat</li>

              <li class="nav-item" style={{display: lihatInvoiceJatuhTempo ? 'block' : 'none' }}>
                <Link class="nav-link" to="/invoice_due_date_view">
                  <i class="bi bi-file-earmark-excel" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Lihat Inv Blm Lunas&nbsp; <span class="badge bg-danger">{ totalData }</span></span>
                </Link>
              </li>

              {/* <li class="nav-item" style={{display: lihatInvoiceBelumLunas ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-file-earmark-excel" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lihat Inv Belum Lunas</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: lihatInvoiceLunas ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-file-earmark-check" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lihat Inv Lunas</span>
                </Link>
              </li> */}

              <li class="nav-item" style={{display: lihatPembayaranMobilOrangLain ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-car-front" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lihat Pembayaran Mobil</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: lihatPenggunaan ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-car-front-fill" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lihat Penggunaan</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: lihatStokDiMobil ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-check-circle-fill" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lihat Stok Di Mobil</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: lihatPembelianBelumLunas ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-calendar-x" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lihat Pembelian Belum Lunas</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: listRekapOrder ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-calendar-x" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Daftar Rekap Order</span>
                </Link>
              </li>
              
              <li class="nav-heading" style={{display: laporanTotalanSopir || laporanLabaRugi || laporanRekapOrder || laporanPerawatan || laporanLainLain || laporanPembelian || laporanInvoice ? 'block' : 'none' }}>Laporan</li>

              <li class="nav-item" style={{display: laporanTotalanSopir ? 'block' : 'none' }}>
                <Link class="nav-link" to="/driver_report">
                  <i class="bi bi-file-earmark-person-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Lap Totalan Sopir</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: laporanLabaRugi ? 'block' : 'none' }}>
                <Link class="nav-link" to="/profit_report">
                  <i class="bi bi-currency-dollar" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Lap Laba Rugi</span>
                </Link>
              </li>
              <li class="nav-item" style={{display: laporanRekapOrder ? 'block' : 'none' }}>
                <Link class="nav-link" to="/recap_order_report">
                  <i class="bi bi-file-earmark-spreadsheet-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Lap Rekap Order</span>
                </Link>
              </li>
              <li class="nav-item" style={{display: laporanPerawatan ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-bag-plus" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lap Perawatan</span>
                </Link>
              </li>
              <li class="nav-item" style={{display: laporanLainLain ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-file-pdf-fill" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lap Lain - Lain</span>
                </Link>
              </li>
              <li class="nav-item" style={{display: laporanPembelian ? 'block' : 'none' }}>
                <Link class="nav-link" to="#">
                  <i class="bi bi-handbag" style={{color:'grey'}}></i>
                  <span style={{color:'grey'}}>Lap Pembelian</span>
                </Link>
              </li>
              <li class="nav-item" style={{display: laporanInvoice ? 'block' : 'none' }}>
                <Link class="nav-link" to="/invoice_report">
                  <i class="bi bi-cloud-arrow-down-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Lap Invoice</span>
                </Link>
              </li>
              <li class="nav-item" style={{display: laporanInvoiceBlmLunas ? 'block' : 'none' }}>
                <Link class="nav-link" to="/invoice_due_date_report">
                  <i class="bi bi-stickies-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Lap Invoice Blm Lunas</span>
                </Link>
              </li>

              <li class="nav-heading" style={{display: users ? 'block' : 'none' }}>Setting</li>

              <li class="nav-item" style={{display: users ? 'block' : 'none' }}>
                <Link class="nav-link" to="/profile">
                  <i class="bi bi-person-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Profile</span>
                </Link>
              </li>
              <li class="nav-item" style={{display: users ? 'block' : 'none' }}>
                <Link class="nav-link" to="/changepassword">
                  <i class="bi bi-lock-fill" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Ganti Password</span>
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="#" onClick={keluar}>
                  <i class="bi bi-box-arrow-right" style={{color:'black'}}></i>
                  <span style={{color:'black'}}>Logout</span>
                </Link>
              </li>
            </ul>

          </aside>
          
          
          </div>
        )
}

export default Header_admin;