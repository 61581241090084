import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth:"500px",
    width: "100%",
    padding: "50px",
    fontSize: "20px"
  },  
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

export const TimeoutWarningModal = ({isOpen, onRequestClose}) => {  
  const onLogOffCall = () => {
    // Implement your logout functionality here, e.g. clear the users login cache or hit your signout server
    window.location.href = "http://localhost:3000/admin"
  }

  return (
    <div> 
      <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>Session Timeout</h2>
        <div>Sesi login anda sudah habis. Harap pilih anda tetap pada halaman atau keluar. Anda akan otomatis logout jika tidak memilih.</div>
        <br/>
        <button onClick={onLogOffCall}>Logout</button>
        <button onClick={onRequestClose}>Lanjutkan</button>
      </Modal>
    </div>  
  );
}
