import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Superiority() {

    console.log(window.location.origin);
  
  return(
    <div>
      <div id="header_portofolio">   
        <div class="center text-center">
            <div class="navigation_portofolio">
              <div class="nav-container">
                <div class="brand">
                  <img class="left-img" src={require('../../assets_landingpage/img/fasworld.jpg')} width="110px" height="60px" alt="" />
                </div>
                <nav class="nav__atas_portofolio">
                  <div class="nav-mobile-portofolio">
                    <a id="nav-toggle" href="#"><span></span></a>
                  </div>
                  <ul class="nav-list">
                    <li><Link to="/halaman_utama">Beranda</Link></li>
                    <li><Link to="/portofolio">Portofolio</Link></li>
                    <li><Link to="/superiority">Keunggulan</Link></li>
                    <li><Link to="/kontak">Kontak</Link></li>
                    <li><Link to="/termsandcondition">Syarat dan Ketentuan</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
        </div> 
      </div>
      
      <div class="container_portofolio">
        
        <div class="title_portofolio">
            <h2 class="section-title text-left">Goods Delivery Service / Express Packages Overseas Cheap Fast World Express</h2>
            <p class="lead main text-left">
            As an international shipping company that provides cheap overseas parcel and document delivery services such as DHL and Fedex. We provide a wide selection of services or services for you. Where, everything is express or express. Because, the average <b>estimated delivery time (ETD) is between 2-5 business days</b>. In this case, it depends on the country of destination for your package and documents. </p>

            <p class="lead main text-left"><b>The following are courier delivery services to overseas that we provide. Among others, namely:</b></p>   

            <p class="lead main text-left">First, document delivery services abroad. Second, Package or Goods delivery services abroad. Third, sample delivery services (sample goods). Fourth, food delivery services abroad. Fifth, cosmetic delivery services abroad. Sixth, furniture or furniture delivery services abroad. Seventh, overseas transfer services. Eighth, delivery services for car or motorcycle spare parts abroad. Ninth, delivery services for industrial machinery spare parts. Tenth, delivery services for handicrafts abroad. Eleventh, Herbal Delivery Services abroad. Twelfth, <b>Coffee and Bean Delivery Service</b> . Thirteenth, <b>Cigarette Delivery Service Overseas.</b></p>

            <p class="lead main text-left">In addition, we also provide international air cargo shipping services abroad. Which, we provide 2 types of services, namely: Port to Port and Door to Port. This service is specifically for urgent items that must arrive within 1-2 days. For example: delivery of fruit, vegetables, crops, ornamental plants, fish, frozen food, and so on. </p>

            <p class="lead main text-left">In addition, we also provide packing services, export document management, MSDS manufacture, V-Legal document creation. And, Delivery Service (Shopping).</p>

            <p class="lead main text-left">In fact, <b>Package Consolidation</b> services are also available . Also, <b>Free Storage</b> (Ware House) for customers who use the <b>FAST WORLD EXPRESS delivery service</b></p>
        
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/good.png')} height="300px" alt="Port 1" />
                    <div class="card-body">
                    <h5 class="card-title">GOOD</h5>
                    </div>
                </div>
                <div class="card">
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/document.png')} height="300px" alt="Port 2" />
                    <div class="card-body">
                    <h5 class="card-title">DOCUMENT</h5>
                    </div>
                </div>
                <div class="card">  
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/sample.png')} height="300px" alt="Port 3" />
                    <div class="card-body">
                    <h5 class="card-title">SAMPLE</h5>
                    </div>
                </div>
                <div class="card">  
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/food.png')} height="300px" alt="Port 3" />
                    <div class="card-body">
                    <h5 class="card-title">FOOD</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/cosmetics.png')} height="300px" alt="Port 1" />
                    <div class="card-body">
                    <h5 class="card-title">COSMETICS</h5>
                    </div>
                </div>
                <div class="card">
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/herbs.png')} height="300px" alt="Port 2" />
                    <div class="card-body">
                    <h5 class="card-title">HERBS</h5>
                    </div>
                </div>
                <div class="card">  
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/spareparts.png')} height="300px" alt="Port 3" />
                    <div class="card-body">
                    <h5 class="card-title">SPARE PARTS</h5>
                    </div>
                </div>
                <div class="card">  
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/furniture.png')} height="300px" alt="Port 3" />
                    <div class="card-body">
                    <h5 class="card-title">FURNITURE</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/cargo.png')} height="300px" alt="Port 1" />
                    <div class="card-body">
                    <h5 class="card-title">CARGO</h5>
                    </div>
                </div>
                <div class="card">
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/packing.png')} height="300px" alt="Port 2" />
                    <div class="card-body">
                    <h5 class="card-title">PACKING</h5>
                    </div>
                </div>
                <div class="card">  
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/deliveryservice.png')} height="300px" alt="Port 3" />
                    <div class="card-body">
                    <h5 class="card-title">DELIVERY SERVICE</h5>
                    </div>
                </div>
                <div class="card">  
                    <img class="card-img-top-superiority" src={require('../../assets_landingpage/img/transfer.png')} height="300px" alt="Port 3" />
                    <div class="card-body">
                    <h5 class="card-title">TRANSFER</h5>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div id="footer_portofolio" class="dark-wrapper-portofolio">
          <div class="container inner">
              <div class="row logo_footer">
                  <div class="col-sm-12">
                    Fast World Express
                  </div>
              </div>
              <div class="row alamat">
                  <div class="col-sm-12">
                    Wiyung Indah XVIII NO 16 A,<br />
                    Jawa Timur, Kota Surabaya, Kecamatan Wiyung.
                  </div>
              </div>
              <div class="row social">
                  <div class="col-sm-12">
                      <div class="social-bar">
                          <a href="#" class="fa fa-instagram tooltipped" title="instagram"></a>
                          <a href="#" class="fa fa-youtube-square tooltipped" title="youtube"></a>
                          <a href="#" class="fa fa-facebook-square tooltipped" title="facebook"></a>
                          <a href="#" class="fa fa-twitter-square tooltipped" title="twitter"></a>
                          <a href="#" class="fa fa-google-plus-square tooltipped" title="google+"></a>
                      </div>
                  </div>
              </div>
              <div class="row copyright">
                  <div class="col-sm-12">
                      &copy; Copyright Fast World Express 2022
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}