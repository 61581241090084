import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Termsandcondition() {

    console.log(window.location.origin);
  
  return(
    <div>
      <div id="header_portofolio">    
          <div class="center text-center">
            <div class="navigation_portofolio">
              <div class="nav-container">
                <div class="brand">
                  <img class="left-img" src={require('../../assets_landingpage/img/fasworld.jpg')} width="110px" height="60px" alt="" />
                </div>
                <nav class="nav__atas_portofolio">
                  <div class="nav-mobile-portofolio">
                    <a id="nav-toggle" href="#"><span></span></a>
                  </div>
                  <ul class="nav-list">
                    <li><Link to="/halaman_utama">Beranda</Link></li>
                    <li><Link to="/portofolio">Portofolio</Link></li>
                    <li><Link to="/superiority">Keunggulan</Link></li>
                    <li><Link to="/kontak">Kontak</Link></li>
                    <li><Link to="/termsandcondition">Syarat dan Ketentuan</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
      </div>
      
      <div class="container_portofolio">
        
        <div class="title_portofolio">
            <h2 class="section-title text-left">Fast World Express Terms and Conditions for Overseas Shipping</h2>
            <p class="lead main text-left">
                <ul>
                    <li>These Terms and Conditions apply to all overseas shipments via Fast World Express </li>
                    <li>Shipping costs listed on the pricelist and website do not include  <b>Insurance, Duty Tax, VAT</b>  in the country</li>
                    <li>The fees listed on the pricelist and website  <b>can change at any time</b>  according to their respective tariff policies</li>
                    <li><b>The delivery time</b>  listed on the Atha Express website is the estimated delivery time, Atha Express is not responsible for any delays or delays caused by technical problems, bad weather, transit times, delays in the customs clearance process in the country</li>
                    <li>Packages sent abroad must be packaged (packaged) as safely as possible (properly) by the sender to protect the package from damage during the shipping process.</li>
                    <li>Packages of Valuables or items with high value are advised to use <b>Insurance</b>.</li>
                    <li><b>Insurance</b> only covers lost packages (does not cover damage to goods in the package), so the sender is obliged to ensure that the package is packaged as safely as possible.</li>
                    <li><b>Complaints</b> about damaged or lost packages must be submitted to Atha Express within <b>7 days</b> after the package is received, if it passes the 7 day time limit then all forms of complaints will not be responded to</li>
                    <li>The maximum value for reimbursement of lost package claims that are not insured by the sender that can be replaced by Fast World Expresss is according to the value of the shipping invoice or the actual price of the goods with a maximum replacement/liability value <b>of $100 USD</b></li>
                    <li>Fast World Express is not responsible or does not replace any package damage caused by improper packing <b>(unsafe packaging) from the sender</b>.</li>
                    <li>Fast World Express is not responsible or does not replace <b>packages that are detained, confiscated, destroyed</b>  by the customs, both in Indonesia and in the destination country, which is caused by the sender not meeting the required customs document requirements and or incorrect documents from the sender.</li>
                    <li>Fast World Express is not responsible or does not replace <b>packages that are detained, confiscated, destroyed</b>  in the destination country because the goods sent are prohibited goods and or are subject to limited restrictions according to regulations or laws in the destination country.</li>
                    <li>Fast World Express is not responsible for or does not replace <b>packages that are detained, confiscated, destroyed</b> in the destination country due to the recipient's failure to comply with documentation and or customs taxes required by customs in the destination country of delivery.</li>
                    <li>If the package is delayed because the recipient's address has moved, the recipient's address cannot be found, the recipient's phone number cannot be contacted, the recipient refuses to pay taxes, the recipient cannot meet the customs documentation requirements in the destination country more than 7 days after the package arrives in the destination country and there is no news both from the sender and recipient, Atha Express has the right to issue instructions  for the <b>destruction or confiscation of packages</b> by the recipient's destination country to the cargo party..</li>
                    <li>Regarding <b>Packages that are returned</b> to the sender due to various customs problems and the recipient in the destination country, all costs that arise in the return shipping process will be borne by the sender.</li>
                    <li><b>Cancellations</b>  made by the sender after the package has been dropped or handed over to Fast World Express will be subject to a fee, in exchange for the cost of storage, documentation, checking and so on, a minimum of IDR 100,000 to IDR 200,000 depending on the actions taken to handle shipments.</li>
                    <li>The sending party is obliged to provide <b>actual information</b> regarding the type of goods, the quantity and value of the package goods.</li>
                    <li>The Sending Party is prohibited from sending goods which include <b>dangerous goods, illegal drugs, drugs,</b> and goods that are included in the prohibited categories by laws in force both in Indonesia and in the destination country.</li>
                    <li>Fast World Express is not responsible for any risks caused by <b>incorrect information</b>  from the sender regarding the contents of the package.</li>
                    <li>The sending party must follow  <b>the terms and conditions</b> set by Fast World Express published on all Fastworldexpress.com websites</li>
                </ul>
            </p>
            <p class="lead main text-left"><b>*** By sending a package via Fast World Express, the sender is deemed to have agreed to all shipping terms and conditions that apply to Fast World Express.</b></p>
        
        </div>
      </div>

      <div id="footer_portofolio" class="dark-wrapper-portofolio">
          <div class="container inner">
              <div class="row logo_footer">
                  <div class="col-sm-12">
                    Fast World Express
                  </div>
              </div>
              <div class="row alamat">
                  <div class="col-sm-12">
                    Wiyung Indah XVIII NO 16 A,<br />
                    Jawa Timur, Kota Surabaya, Kecamatan Wiyung.
                  </div>
              </div>
              <div class="row social">
                  <div class="col-sm-12">
                      <div class="social-bar">
                          <a href="#" class="fa fa-instagram tooltipped" title="instagram"></a>
                          <a href="#" class="fa fa-youtube-square tooltipped" title="youtube"></a>
                          <a href="#" class="fa fa-facebook-square tooltipped" title="facebook"></a>
                          <a href="#" class="fa fa-twitter-square tooltipped" title="twitter"></a>
                          <a href="#" class="fa fa-google-plus-square tooltipped" title="google+"></a>
                      </div>
                  </div>
              </div>
              <div class="row copyright">
                  <div class="col-sm-12">
                      &copy; Copyright Fast World Express 2022
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}