import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Portofolio() {

  
  return(
    <div>
      <div id="header_portofolio">    
        <div class="center text-center">
            <div class="navigation_portofolio">
              <div class="nav-container">
                <div class="brand">
                  <img class="left-img" src={require('../../assets_landingpage/img/fasworld.jpg')} width="110px" height="60px" alt="" />
                </div>
                <nav class="nav__atas_portofolio">
                  <div class="nav-mobile-portofolio">
                    <a id="nav-toggle" href="#"><span></span></a>
                  </div>
                  <ul class="nav-list">
                    <li><Link to="/halaman_utama">Beranda</Link></li>
                    <li><Link to="/portofolio">Portofolio</Link></li>
                    <li><Link to="/superiority">Keunggulan</Link></li>
                    <li><Link to="/kontak">Kontak</Link></li>
                    <li><Link to="/termsandcondition">Syarat dan Ketentuan</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
        </div>
      </div>
      
      <div class="container_portofolio">
        
        <div class="title_portofolio">
            <h2 class="section-title text-left">Portofolio Kirim Paket ke Luar Negeri Kilat Fast World Express</h2>
            <p class="lead main text-left">
            Berikut ini merupakan beberapa contoh portofolio jasa pengiriman barang kilat ke luar negeri murah Fast World Express. Kami telah berhasil mengirimkan lebih dari 1 juta paket ke luar negeri sejak tahun 2014. Jadi, tidak perlu ragu akan kemampuan, pengalaman dan kecepatan kami.

            NB : Ini merupakan portofolio asli Fast World Express. Kami tidak bisa menampilkan semua gambar dalam website ini. Sebab, akan memperlambat loading website. </p>
        
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <Link to="/assets_landingpage/img/fw1.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw1.jpg')} height="200px" alt="Port 1" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw2.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw2.jpg')} height="200px" alt="Port 2" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw3.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw3.jpg')} height="200px" alt="Port 3" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw4.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw4.jpg')} height="200px" alt="Port 4" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <Link to="/assets_landingpage/img/fw5.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw5.jpg')} height="200px" alt="Port 5" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw6.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw6.jpg')} height="200px" alt="Port 6" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw7.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw7.jpg')} height="200px" alt="Port 7" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw8.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw8.jpg')} height="200px" alt="Port 8" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <Link to="/assets_landingpage/img/fw9.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw9.jpg')} height="200px" alt="Port 9" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw10.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw10.jpg')} height="200px" alt="Port 10" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw11.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw11.jpg')} height="200px" alt="Port 11" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw12.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw12.jpg')} height="200px" alt="Port 12" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <Link to="/assets_landingpage/img/fw13.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw13.jpg')} height="200px" width="150px" alt="Port 13" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw14.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw14.jpg')} height="200px" width="150px" alt="Port 14" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
                <div class="card">
                    <Link to="/assets_landingpage/img/fw15.jpg" target="_blank">
                    <img class="card-img-top" src={require('../../assets_landingpage/img/fw15.jpg')} height="200px" width="150px" alt="Port 15" />
                    {/* <div class="card-body">
                    <h5 class="card-title">Card title</h5>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div> */}
                    </Link>
                </div>
            </div>
        </div>
      </div>

      <div id="footer_portofolio" class="dark-wrapper-portofolio">
          <div class="container inner">
              <div class="row logo_footer">
                  <div class="col-sm-12">
                    Fast World Express
                  </div>
              </div>
              <div class="row alamat">
                  <div class="col-sm-12">
                    Wiyung Indah XVIII NO 16 A,<br />
                    Jawa Timur, Kota Surabaya, Kecamatan Wiyung.
                  </div>
              </div>
              <div class="row social">
                  <div class="col-sm-12">
                      <div class="social-bar">
                          <a href="#" class="fa fa-instagram tooltipped" title="instagram"></a>
                          <a href="#" class="fa fa-youtube-square tooltipped" title="youtube"></a>
                          <a href="#" class="fa fa-facebook-square tooltipped" title="facebook"></a>
                          <a href="#" class="fa fa-twitter-square tooltipped" title="twitter"></a>
                          <a href="#" class="fa fa-google-plus-square tooltipped" title="google+"></a>
                      </div>
                  </div>
              </div>
              <div class="row copyright">
                  <div class="col-sm-12">
                      &copy; Copyright Fast World Express 2022
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}