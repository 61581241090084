import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import NumberFormat from 'react-number-format';

export default function Cektarif() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [cekTarif, setCekTarif] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        'https://athaexpress.net/backend/ongkir/api/cek_tarif/'+params.get("country")+'/'+params.get("weight"),);
      const json = await res.json();
      if(json.status === "true"){
        setCekTarif(json.data);
      }else{
        setCekTarif("Data Tidak Tersedia");
      }
    };

    fetchData();
    
  },[]);
  
  return(
    <div>
      <div id="header_landingpage">    
        <div class="center text-center">
            <div class="navigation">
              <div class="nav-container">
                <div class="brand">
                  <img class="left-img" src={require('../../assets_landingpage/img/fasworld.jpg')} width="110px" height="60px" alt="" />
                </div>
                <nav class="nav__atas">
                  <div class="nav-mobile">
                    <a id="nav-toggle" href="#!"><span></span></a>
                  </div>
                  <ul class="nav-list">
                    <li><Link to="/halaman_utama">Beranda</Link></li>
                    <li><Link to="/portofolio">Portofolio</Link></li>
                    <li><Link to="/superiority">Keunggulan</Link></li>
                    <li><Link to="/kontak">Kontak</Link></li>
                    <li><Link to="/termsandcondition">Syarat dan Ketentuan</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="banner">
              <div class="cektarif">
                <div class="title-cektarif">
                  Hasil Cek Tarif
                </div>
                <div class="body-cektarif">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="title_body_cektarif">Asal</div>
                      <div class="desc_body_cektarif">{params.get("from")}</div>
                    </div>
                    <div class="col-sm-4">
                      <div class="title_body_cektarif">Tujuan</div>
                      <div class="desc_body_cektarif">{params.get("country")}</div>
                    </div>
                    <div class="col-sm-4">
                      <div class="title_body_cektarif">Berat/Kg</div>
                      <div class="desc_body_cektarif">{params.get("weight")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      
      <div id="story" class="light-wrapper">
      
            {
              cekTarif != 'Data Tidak Tersedia' ?
              cekTarif.map(item => (
              
              <div class="row item_price">
                <div class="col-sm-4">
                  <div class="title_item_price">Tipe Item</div>
                  <div class="desc_item_price">{item.name}</div>
                  <div class="note_item_price">*Syarat dan ketentuan berlaku</div>
                </div>

                <div class="col-sm-4">
                  <div class="title_item_price">Tipe Price</div>
                  <div class="desc_item_price">
                    {item.type_price == "Ekspress" ? (
                      item.type_price + ' ( Berat ' +item.weight + ' Kg)'
                    ):(
                      item.type_price + ' ( Per Kg = ' + item.price_delivery + ' x ' + params.get("weight") + ')'
                    )}
                  </div>
                </div>
          
                <div class="col-sm-4">
                  <div class="title_item_price">Harga</div>
                  <div class="desc_item_price_harga">
                    {item.type_price != "Ekspress" ? (
                       <NumberFormat value={item.price_delivery*params.get("weight")} displayType={'text'} thousandSeparator={true} prefix={'IDR'} /> 
                    ):(
                      <NumberFormat value={item.price_delivery} displayType={'text'} thousandSeparator={true} prefix={'IDR'} />
                    )}
                   
                  </div>
                </div>
              </div>
            ))
            :
            <div class="row item_price">
              <div class="col-sm-12">
                <div class="title_item_price"></div>
                <div class="desc_item_price">Data Tidak Ditemukan</div>
              </div>
            </div>
          }
      </div>

      <div id="footer_landingpage" class="dark-wrapper">
          <div class="container inner">
              <div class="row logo_footer">
                  <div class="col-sm-12">
                      Express
                  </div>
              </div>
              <div class="row alamat">
                  <div class="col-sm-12">
                      Jl Raya Surabaya,<br />
                      Jawa Timur, Kota Surabaya, Lakarsantri.
                  </div>
              </div>
              <div class="row social">
                  <div class="col-sm-12">
                      <div class="social-bar">
                          <a href="#" class="fa fa-instagram tooltipped" title="instagram"></a>
                          <a href="#" class="fa fa-youtube-square tooltipped" title="youtube"></a>
                          <a href="#" class="fa fa-facebook-square tooltipped" title="facebook"></a>
                          <a href="#" class="fa fa-twitter-square tooltipped" title="twitter"></a>
                          <a href="#" class="fa fa-google-plus-square tooltipped" title="google+"></a>
                      </div>
                  </div>
              </div>
              <div class="row copyright">
                  <div class="col-sm-12">
                      &copy; Copyright TastyBox 2013
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}