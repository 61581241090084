import React from 'react';

export default function Home() {
  
  return(
    <div class="bgimg">
      <div class="topleft">
        {/* <p><img class="left-img" src={require('../../assets_landingpage/img/revasamudra.png')} width="150px" height="150px" alt="" /></p> */}
      </div>
      <div class="middle">
        <h1>COMING SOON</h1>
        <p>30 days left</p>
      </div>
      <div class="bottomleft">
        <p>@Copyright Reva Samudra 2022</p>
      </div>
    </div>
  );
}