import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { delete_order_recap } from '../../../Context/actions';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';

import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

function Order_recap() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory();

  const [orderRecap, setOrderRecap] = useState([]);
  const [dateOrderRecap, setDateOrderRecap] = useState();
  const [customerCode, setCustomerCode] = useState();
  const [customerName, setCustomerName] = useState();
  const [numberPlate, setNumberPlate] = useState();
  const [driverCode, setDriverCode] = useState();
  const [driverName, setDriverName] = useState();
  const [from, setFrom] = useState();
  const [destination, setDestination] = useState();
  const [activity, setActivity] = useState();
  const [containerSize, setContainerSize] = useState();
  const [deliveryDestinationCode, setDeliveryDestinationCode] = useState();
  const [deliveryDestinationName, setDeliveryDestinationName] = useState();
  const [containerNumber, setContainerNumber] = useState();
  const [pocketMoney, setPocketMoney] = useState();
  const [coolieMoney, setCoolieMoney] = useState();
  const [otherMoney, setOtherMoney] = useState();
  const [billMoney, setBillMoney] = useState();
  const [downpaymentMoney, setDownpaymentMoney] = useState();
  const [harbor, setHarbor] = useState();
  const [harborMoney, setHarborMoney] = useState();
  const [stapelMoney, setStapelMoney] = useState();
  const [depo, setDepo] = useState();
  const [depoMoney, setDepoMoney] = useState();
  const [realStapelMoney, setRealStapelMoney] = useState();
  const [overtimeMoney, setOvertimeMoney] = useState();


  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");


  const [loading, setLoading] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingResi, setLoadingResi] = useState(false);
  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);
  const [progress, setProgress] = useState(0);

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');


  useEffect(() => {
    let url;
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };
    const fetchData = async () => {
    if(query == ''){
      
        url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showOrderRecapGetDataPagination';
        const res = await fetch(
            url,settings
        );
        const json = await res.json();
        
        setPage(page);
        setPages(json.pages);
        setRows(json.rows);
        
        };

        
    }

    fetchData();
}, [page, keyword]);

useEffect(() => {
    let url;
    setProgress(progress + 40);
    setAlert(true);
    setAlertContent("Load data ...");
    if(query == ''){
        if(awal == undefined){
            url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showOrderRecapPagination/0';
        }else{
            url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showOrderRecapPagination/'+awal;
        }
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'usercode': localStorage.getItem('username'),
                'token': localStorage.getItem('token'),
            }
        };
        
        const fetchData = async () => {
        const res = await fetch(
            url,settings
        );
        const json = await res.json();
        
        setProgress(100);
        console.log(json.data)
        setOrderRecap(json.data);
        setAlert(false);
        
        };
        fetchData();
    }
  },[]);

const changePage = ({ selected }) => {
    setProgress(progress + 40);
    setAlert(true);
    setAlertContent("Load data ...");

    if(query == ''){
        setPage(selected+1);
        
        let awal;
        
        awal = (Number((selected+1)+'0') - 10)

        setAwal(awal);
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'usercode': localStorage.getItem('username'),
                'token': localStorage.getItem('token'),
            }
        };
        const fetchData = async () => {
        const res = await fetch(
            'https://revasamudra.ivantie.com/backend/revasamudra/api/showOrderRecapPagination/'+awal,settings
        );
        const json = await res.json();
        
        if(json.status == "true"){
            setProgress(100);
            setOrderRecap([]);
            setOrderRecap(json.data);
            setAlert(false);
        }else{
            setProgress(100);
            setAlert(false);
            setMsg(
                "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
            );
        }
        
        };
        fetchData();
    }else{
        setPage(selected+1);
        let awal;
        
        awal = (Number((selected+1)+'0') - 10)

        setAwal(awal);
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'usercode': localStorage.getItem('username'),
                'token': localStorage.getItem('token'),
            }
        };
        const fetchData = async () => {
        const res = await fetch(
            'https://revasamudra.ivantie.com/backend/revasamudra/api/showOrderRecapPaginationSearch/'+awal+'/'+query,settings
        );
        const json = await res.json();
        
        if(json.status == "true"){
            setProgress(100);
            setAlert(false);
            setOrderRecap([]);
            setOrderRecap(json.data);
            
            setPages(json.pages);
            setRows(json.rows);
        }else{
            setProgress(100);
            setAlert(false);
            setMsg(
                "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
            );
        }
        
        };
        fetchData();
    }
    
};

function searchData(){
    setProgress(progress + 40);
    setAlert(true);
    setAlertContent("Load data ...");
    setLoadingCari(true)
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };
    const fetchData = async () => { 
    const res = await fetch(
        'https://revasamudra.ivantie.com/backend/revasamudra/api/showOrderRecapPaginationSearch/0/'+query,settings
    );
    const json = await res.json();
    
    if(json.status == "true"){
        setProgress(100);
        setAlert(false);
        setLoadingCari(false)
        setOrderRecap([]);
        setPages(0);
        setRows(0);
        setPage(1);
        setOrderRecap(json.data);
        setPages(json.pages);
        setRows(json.rows);
        
    }else{
        setProgress(100);
        setAlert(false);
        setLoadingCari(false)
        setOrderRecap(json.data);
        setPages(json.pages);
        setRows(json.rows);
        setMsg(
            "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Country!"
        );
    }
    
    };
    fetchData(); 
}; 

function resetPencarian(){
    setLoadingCari(true)
    setLoadingResetPencarian(true)
    setQuery("");
    setTimeout(
        function() {
          setLoadingCari(false)
          setLoadingResetPencarian(false)
          window.location.reload(true);
        }
        ,
        2000
    );
}
  
  function edit_order_recap(id){
    history.push('/edit_order_recap?id='+id);
  }

  
  function delete_order_recap_onclick(id){
    if (window.confirm('Are you sure you want to delete this ?')) {
      setProgress(progress + 40);
      setLoading(true)
      setAlert(true);
      setAlertContent("Proses Simpan ...")

      const promise_login = Promise.resolve(delete_order_recap(id));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                setProgress(100);
                setLoading(false)
                setAlertSeverity("success");
                setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
                setTimeout(() => {
                  history.push('/order_recap');
                  window.location.reload(true);
                }, "3000");
            }else if(value.hasil == '2'){
                setProgress(100);
                setLoading(false)
                setAlertSeverity("success");
                setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
                setTimeout(() => {
                  setAlert(false);
                }, "3000");
            }else if(value.hasil == '3'){
                setProgress(100);
                setLoading(false)
                setAlertSeverity("success");
                setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
                setTimeout(() => {
                  setAlert(false);
                }, "3000");
          }
        });
        
    } else {
    }
  }

  function currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }


  return (
    <div>
    <LoadingBar
      color='#f11946'
      progress={progress}
      onLoaderFinished={() => setProgress(0)}
    />
    <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                  {alertContent}
                </Alert>
    </Snackbar>
    <Header_admin></Header_admin>
  <main id="main" class="main">

    <div class="pagetitle">
      <h1>Data Rekap Order</h1>
      &nbsp;
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
          <li class="breadcrumb-item active">Rekap Order</li>
        </ol>
      </nav>
    </div>
    <div class="row mb-3 justify-content-center align-items-center">
      <div class="col-sm-12">
        {alert ? 
        <Alert severity={alertSeverity}>
          <AlertTitle>Mohon ditunggu</AlertTitle>
          {alertContent}
        </Alert> : 
        <></> 
        }
      </div>
    </div>
    <section class="section">
    <div class="row">
                  <div class="col-lg-4">
                      <button className="button_tambah_item" style={{width: "100%"}} onClick={(e) => {
                          e.preventDefault();
                          window.location.href='/add_order_recap';
                          }}>
                        <i className="fa fa-plus"
                          style={{ marginRight: "5px" }}
                        />
                        &nbsp;
                        Buat Rekap Order
                      </button>
                  </div>
                  <div class="col-lg-4 mt-1">
                  <input
                        type="text"
                        className="form-control"
                        style={{width: "100%"}}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Cari sesuatu disini..."
                      />
                  </div>
                  <div class="col-lg-2">
                  <button type="button" style={{width: "100%"}} onClick={searchData} className="button_cari" disabled={loadingCari}>
                        {loadingCari ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-search"
                          style={{ marginRight: "5px" }}
                        />)}
                        {loadingCari && <span>Cari</span>}
                        {!loadingCari && <span>Cari</span>}
                      </button>
                  </div>
                  
                  <div class="col-lg-2">
                      <button type="button" style={{width: "100%"}} onClick={resetPencarian} className="button_reset_pencarian" disabled={loadingResetPencarian}>
                        {loadingResetPencarian ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-refresh"
                          style={{ marginRight: "5px" }}
                        />)}
                        {loadingResetPencarian && <span>Reset Pencarian</span>}
                        {!loadingResetPencarian && <span>Reset Pencarian</span>}
                      </button>
                  </div>
                  
                </div>
      <br />
      <div class="row"> 
        <div class="col-lg-12">
        <div>
              <div class="table-responsive">
              <table className="table table-hover table-bordered">
                  <thead>
                  <tr>
                      <th>No Rekap<br/>(No Invoice)</th>
                      <th>Plat Nomor<br/>(Sopir)</th>
                      <th>No Kontainer</th>
                      <th>Pelanggan</th>
                      <th>Dari / Tujuan</th>
                      <th>Note</th>
                      <th>Tanggal</th>
                      <th>Bon</th>
                      <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                  orderRecap != 'Data Tidak Tersedia' ?
                  orderRecap.map((order) => (
                      <tr>
                      <td>
                        {order.id}<br/>
                        ({order.invoiceNumber})
                      </td>
                      <td>
                        {order.numberPlate}<br/>
                        ( {order.driverName} )
                      </td>
                      <td>{order.containerNumber}</td>
                      <td>{order.customerName}</td>
                      <td>
                        <b>Dari :</b> {order.from}<br/>
                        <b>Tujuan :</b> {order.destination}
                      </td>
                      <td>{order.note}</td>
                      <td>{order.dateOrderRecap}</td>
                      <td><b>{currencyFormat(Number(order.billMoney))}</b></td>
                      <td>
                        <div class="w3-show-inline-block">
                          <div class="w3-bar">
                            <button type="button" className="button_edit" disabled={loading} onClick={() => edit_order_recap(order.id)}>
                              {loading ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-edit"
                                style={{ marginRight: "5px" }}
                              />)}
                              {loading && <span>Edit</span>}
                              {!loading && <span>Edit</span>}
                            </button>&nbsp;
                            <button type="button" className="button_hapus" disabled={loading} onClick={() => delete_order_recap_onclick(order.id)}>
                              {loading ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-times-circle"
                                style={{ marginRight: "5px" }}
                              />)}
                              {loading && <span>Hapus</span>}
                              {!loading && <span>Hapus</span>}
                            </button>
                          </div>
                        </div>
                      </td>
                      </tr>
                  ))
                  :
                  <tr>
                      <td colSpan={9}>Data Tidak Tersedia</td>
                  </tr>
                  }
                  </tbody>
              </table>
              </div>
              <p>
                  Total Rows: {rows} Page: {page} of {pages}
              </p>
              <p className="has-text-centered has-text-danger">{msg}</p>

              <div id="container">
                  <ReactPaginate
                      previousLabel={"< Prev"}
                      nextLabel={"Next >"}
                      pageCount={pages}
                      onPageChange={changePage}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      containerClassName={'pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}
                  />
              </div>
          </div>

        </div>
      </div>
    </section>

  </main>
  <Footer_admin></Footer_admin>
    </div>
  );
}

export default Order_recap;